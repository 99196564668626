import { useSelector } from "react-redux";
import { Axios } from "../Hooks/useAxiosInstance";


export const TournamentAPI = () => {

    const { route, user } = useSelector(state => state.default)

    const apis = ({ route, api_token }) => ({
        get: async () => await getRecord(route, api_token),
        getTournaments: async (page, limit, filters) => await getRecords(api_token, page, limit, filters),
        // getLive: async () => await getLiveRecord(api_token),
        getTournamentLiveMatches: async (id) => await getTournamentLiveMatchesRecord(id, api_token),
        getByID: async (id) => await getRecordByID(id, route, api_token),
        getTeamsByID: async (id) => await getTeamsByAgeGroupID(id, route, api_token),
        getRegisteredTeamsByID: async (age_slug) => await getRegisteredTeamsByAgeGroupID(age_slug, route, api_token),
        getFixtureByID: async (id) => await getFixtureByAgeGroupID(id, route, api_token),
        getTournamentsForTeam: async (team_slug, year) => await getTournamentsForTeam( team_slug, year, route, api_token),
        getRecordsForTeam: async (team_slug, year) => await getRecordsForTeam(team_slug, year, route, api_token),
        getUpcomingTournaments: async () => await getUpcomingTournaments(route, api_token),
        getMyTournaments: async (slug) => await getMyTournaments(route, api_token, slug),
    })

    async function getUpcomingTournaments(route, api_token) {
        const url = route + "tournament/upcoming";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }
    
    async function getMyTournaments(route, api_token, slug) {
        const url = route + `${slug}/tournament/upcoming`;
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getTournamentsForTeam(team_slug, year, route, api_token) {
        const url = route + "tournament/team/"+ team_slug+'?year='+year;
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getRecordsForTeam(team_slug, year, route, api_token) {
        const url = route + "tournament/team/"+ team_slug + "/records/" + year;
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getRecords(api_token, page = 1, limit = 10, filters={}) {
        let url = new URL(`https://api.primetime-tournaments.com/api/tournament`);
        url.searchParams.append("page", page);
        url.searchParams.append("limit", limit);
        if (filters.search) {
            const search = filters.search.toLowerCase();
            url.searchParams.append("search", search);
        }

        Object.entries(filters).forEach(([key, value]) => {
            if (key !== "search" && value) { // Skip 'search' here, it's already handled
                url.searchParams.append(key, value);
            }
        });

        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getRecord(route, api_token) {
        const url = route + "tournament/schedule";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getLiveRecord(api_token) {
        const url = "user/tournament/live";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getTournamentLiveMatchesRecord(id, api_token) {
        const url = "user/tournament/" + id + "/live-match";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getRecordByID(id, route, api_token) {
        const url = "/tournament/" + id;
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getTeamsByAgeGroupID(id, route, api_token) {
        const url = route + "tournament/age-group/" + id + '/team';
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getRegisteredTeamsByAgeGroupID(age_slug, route, api_token) {
        const url ="/tournament/age-group/" + age_slug + "/registered-team";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getFixtureByAgeGroupID(id, route, api_token) {
        const url = "/tournament/age-group/" + id + '/fixture';
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    return apis({ route: route, api_token: user.api_token });
}

