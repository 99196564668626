import React, { useState } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Card, Button } from "react-bootstrap";
import { TournamentAPI } from "../../Api/Tournament";
import { useQuery } from "react-query";
import { Toast } from "../../Hooks/useToast.js";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { WEB_ROUTES } from "../../constants";
import { baseRoute } from "../../Helper";
import styles from "../../Components/Layouts/styles/PrimeTimeLayout.module.scss";


export default function Prime() {
    const [upcomingTournaments, setUpcomingTournaments] = useState([]);
    const navigate = useNavigate();
    const tournamentAPI = TournamentAPI();
    const { data, isLoading, isError, error } = useQuery(
        'upcomingTournaments',
        async () => {
            const response = await tournamentAPI.getUpcomingTournaments();
            return response.data;
        },
        {
            onSuccess: (toursData) => {
                setUpcomingTournaments(toursData);
            },
            onError: (err) => Toast(err.message, 'error', false),
            refetchOnMount: true,
            refetchOnReconnect: true,
            refetchOnWindowFocus: true,
        }
    );

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <div className={styles.sectionContainer}>
            <div className={styles.sectionHeader}>
                <h1>Prime Time</h1>
            </div>
            <div className={styles.sectionContent}>
                <div className={styles.sectionContentHeader}>
                    <h2>Upcoming Tournaments</h2>
                </div>
                <div className={styles.sectionContentBody}>
                    <Carousel responsive={responsive}>
                        {upcomingTournaments && upcomingTournaments.length > 0 ? (
                            upcomingTournaments.map((tournament, index) => (
                                <div className="item m-1" key={index}>
                                    <Card className={styles.sectionContentBodyRowItem}>
                                        <Card.Body>
                                            <Card.Title>{tournament.name}</Card.Title>
                                            <Card.Text>{tournament.description}</Card.Text>
                                            <Link to={`/tournament/${tournament.slug}`}>
                                                <Button variant="primary">View</Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))
                        ) : (
                            <div className="item m-1">
                                <Card className={styles.sectionContentBodyRowItem}>
                                    <Card.Body>
                                        <Card.Title>No Upcoming Tournaments</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                        )}
                    </Carousel>
                </div>
            </div>
        </div>

    );
}
