import React, { useEffect } from 'react';

import styles from "./styles/Dashboard.module.scss"

import { Description, EmptyContainer, Loader, SubTitle } from '../../Components/CustomComponents';
import { Row, Col, Card, Button  } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import MatchCard from '../../Components/Cards/MatchCard';
import TournamentCard from '../../Components/Cards/TournamentCard';
import { ROUTES } from './constants';
import { TournamentAPI } from '../../Api/Tournament';
import { Toast } from '../../Hooks/useToast';
import { useQueries, useQueryClient } from 'react-query';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Default from '../../assets/images/default.jpg';


export default function Dashboard({ setPage }) {
    const tournamentAPI = TournamentAPI()
    const user_type = useSelector(state => state.default.route)
    const user = useSelector(state => state.default.user)
    const QUERY_PAYLOAD = {
        select: (data) => data.data,
        onError: (err) => Toast(err.message, 'error', false),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
    }
    const [ upTournamentQuery, tournamentQuery ] = useQueries([
        {
            queryKey: ['tournament/upcoming'],
            queryFn: async () => await tournamentAPI.getUpcomingTournaments(),
            onSuccess: (data) => {
            },
            ...QUERY_PAYLOAD
        },
        {
            queryKey: [user.slug + '/tournaments/'],
            queryFn: async () => await tournamentAPI.getMyTournaments(user.slug),
            onSuccess: (data) => {
            },
            ...QUERY_PAYLOAD
        },
    ])
    const { data: upTournaments, isError: upTournamentError, isLoading: upTournamentLoading } = upTournamentQuery
    const { data: tournaments, isError: tournamentError, isLoading: tournamentLoading } = tournamentQuery

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    
    const handleViewLiveMatches = () => {
        setPage(ROUTES.LIVE_MATCHES)
    }

    const handleViewTournament = () => {
        setPage(ROUTES.TOURNAMENT_LISTING)
    }

    const handleTournamentClick = (data) => {
        setPage(ROUTES.VIEW_TOURNAMENT, data)
    }

    return (
        <div className={styles.Dashboard}>
            <div className={styles.section}>

                <div
                    className={styles.headerContainer}
                >
                    <div className={styles.titleContainer}>

                        <SubTitle
                            text={'Upcoming Tournaments'}
                        />
                        {/* <Description
                            text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}
                            classes='mt-1 fs-8'
                        /> */}

                    </div>
                    {(upTournamentLoading || upTournamentError || _.isEmpty(upTournaments)) ? "" :
                        <button
                            className={styles.viewButton}
                            onClick={handleViewTournament}
                        >
                            View All
                        </button>
                    }
                </div>
                <Row
                    className={`${styles.matchRow} w-100 mt-2 mb-2`}
                    gutter={[0, 24]}
                >
                    {tournamentLoading ? <Loader /> :
                        (tournamentError || _.isEmpty(upTournaments)) ?
                            <EmptyContainer component={<SubTitle text='No Upcoming Tournaments yet' classes='fs-5 fw-lighter' />} /> :
                            <Carousel responsive={responsive}>
                                    {upTournaments.map((tournament, index) => (
                                        <div className="item m-1" key={index}>
                                            <Card className={styles.sectionContentBodyRowItem}>
                                                <Card.Body>
                                                    <Card.Img variant="top" src={tournament.media_url || Default} style={{ maxWidth: '100%', height: '250px', objectFit: 'contain', borderRadius: '4px' }} />
                                                    <Card.Title>{tournament.name}</Card.Title>
                                                    <Card.Text>{tournament.class} - {tournament.sports_type}</Card.Text>
                                                    <Card.Text>{tournament.description}</Card.Text>
                                                    <Button variant="primary" onClick={() => handleTournamentClick(tournament)}>View</Button>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    ))}
                            </Carousel>
                    }
                </Row>
            </div>
            <div className={styles.section}>

                <div
                    className={styles.headerContainer}
                >
                    <div className={styles.titleContainer}>

                        <SubTitle
                            text={'My Tournaments'}
                        />
                        {/* <Description
                            text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}
                            classes='mt-1 fs-8'
                        /> */}

                    </div>
                    {(tournamentLoading || tournamentError || _.isEmpty(tournaments)) ? "" :
                        <button
                            className={styles.viewButton}
                            onClick={handleViewTournament}
                        >
                            View All
                        </button>
                    }
                </div>
                <Row
                    className={`${styles.matchRow} w-100 mt-2 mb-2`}
                    gutter={[0, 24]}
                >
                    {tournamentLoading ? <Loader /> :
                        (tournamentError || _.isEmpty(tournaments)) ?
                            <EmptyContainer component={<SubTitle text='No Tournaments yet' classes='fs-5 fw-lighter' />} /> :
                                <Carousel responsive={responsive}>
                                    {tournaments.map((tournament, index) => (
                                        <div className="item m-1" key={index}>
                                            <Card className={styles.sectionContentBodyRowItem}>
                                                <Card.Body>
                                                    <Card.Img variant="top" src={tournament.media_url || Default} style={{ maxWidth: '100%', height: '250px', objectFit: 'contain', borderRadius: '4px' }} />
                                                    <Card.Title>{tournament.name}</Card.Title>
                                                    <Card.Text>{tournament.class} - {tournament.sports_type}</Card.Text>
                                                    <Card.Text>{tournament.description}</Card.Text>
                                                    <Button variant="primary" onClick={() => handleTournamentClick(tournament)}>View</Button>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    ))}
                            </Carousel>
                        }
                </Row>
            </div>
        </div>
    )
}

