import React from 'react';
import cssStyles from "./index.module.css";
import { CardTitle, Description, SubTitle, Button } from '../../Components/CustomComponents';
import Default from "../../assets/images/default.jpg";
import { Card, Col, Image, Row, Stack } from "react-bootstrap";
import { Table } from "@mui/material";
import CustomBadge from '../../Components/Badges/CustomBadge';

const DetailsContent = ({ data = {} }) => {

  return (
    <div className='row'>
      <Col sm={12} className='mb-4 mt-2'>
        <SubTitle text={data?.name} classes='mb-1'/>
        <Stack direction='horizontal' gap={2}>
          <CustomBadge text={'Coach: ' + data?.coach_name} />
          <CustomBadge text={'Members: ' + data?.team_members.length} />
        </Stack>
      </Col>
      <Col md={12}>
        <Table className='table table-striped'>
          <thead>
            <tr>
              <th>Member Name</th>
              <th>Member Image</th>
            </tr>
          </thead>
          <tbody>
            {data?.team_members.map((record, idx) => (
              <tr key={idx}>
                <td>{record.name}</td>
                <td>
                  <Image
                    src={record.image_slug || Default}
                    roundedCircle
                    className={cssStyles.avatarImage}
                    style={{ width: "50px", height: "50px" }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </div>
  );
}

export default DetailsContent;
