import React, {useState} from 'react'
import { useQuery } from "react-query";
import { CardTitle, Description, SubTitle, Button} from '../../Components/CustomComponents';
import { TournamentAPI } from "../../Api/Tournament.js";
import { useSelector } from "react-redux";
import cssStyles from "./index.module.css";
import Default from "../../assets/images/default.jpg";
import { Card, Col, Image, Row, Form, Table, Modal } from "react-bootstrap";

const TeamRecords = ({ team_slug = '' }) => {
    const tournamentAPI = TournamentAPI();
    const user_type = useSelector(state => state.default.route);
    const [year, setYear] = useState(new Date().getFullYear());
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [tourRecord, setTourRecord] = useState('');

    const years = [
        new Date().getFullYear() + 1,
        new Date().getFullYear(),
        new Date().getFullYear() - 1,
        new Date().getFullYear() - 2,
        new Date().getFullYear() - 3
    ];
    const [tournaments, setTournaments] = useState([]);

    const { data, isError, isLoading } = useQuery({
        queryKey: [user_type + 'tournament/team/'+team_slug+'/records/'+year],
        queryFn: async () => await tournamentAPI.getRecordsForTeam(team_slug, year),
        select: (data) => data.data,
        onSuccess: (data) => {
            data.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
            setTournaments(data);

        }
    })

    const handleViewClick = (record) => {
        let wins = 0;
        let losses = 0;
        record.map((match) => {
            if (match.winner_slug === match.register_slug) {
                wins += 1;
            } else {
                losses += 1;
            }
        })
        setTourRecord({wins, losses});
        setSelectedRecord(record);
        setShowModal(true);
    }

    const handleClose = () => {
        setSelectedRecord(null);
        setShowModal(false);
    }


  return (

    <div>
      <Row>
        <Col md={12} className='m-2'>
            <Row>
                <Col md={10}>
                    <SubTitle
                        text='Team Records'
                        classes='mb-4 mt-2'
                    />
                </Col>
                <Col md={2}>
                    <Form.Control 
                        as="select" 
                        className="form-select"
                        name="year"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                    >
                        {years.map((year, idx) => (
                            <option key={idx} value={year}>{year}</option>
                        ))}
                    </Form.Control>
                </Col>
            </Row>
        </Col>
        <Col md={12}>
            <Row>
            {tournaments.length === 0 ? (
                    <p>No tournaments/records found.</p>
                ) : (
                    (tournaments ?? []).map((record, idx) => (
                        <div key={idx}>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Tournament</th>
                                        <th>Sports Type</th>
                                        <th>Season</th>
                                        <th>Records</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key={idx}>
                                        <td>{record.name}</td>
                                        <td>{record.sports_type}</td>
                                        <td>{record.season}</td>
                                        <td>
                                            <Button
                                                text="View"
                                                onClick={() => handleViewClick(record.records)}
                                                classes="btn btn-primary"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>

                            {selectedRecord && (
                                <Modal show={showModal} onHide={handleClose} size="lg">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Matches ({tourRecord.wins} - {tourRecord.losses})</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Table bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Match (Bracket/Round/Seed)</th>
                                                    <th>Team A</th>
                                                    <th>Score A</th>
                                                    <th>Team B</th>
                                                    <th>Score B</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedRecord.map((match, idx) => {
                                                    let teamA = match.teamA_slug === match.register_slug ? `${cssStyles.highlight}` : '';
                                                    let teamB = match.teamB_slug === match.register_slug ? `${cssStyles.highlight}` : '';
                                                    return (
                                                    <tr key={idx}>
                                                        <td>{match.bracket} / {match.round} / {match.seed} </td>
                                                        <td className={teamA}>{match.teamA_name}</td>
                                                        <td>{match.score_teamA}</td>
                                                        <td className={teamB}>{match.teamB_name}</td>
                                                        <td>{match.score_teamB}</td>
                                                    </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </Modal.Body>
                                </Modal>
                            )}
                        </div>
                    ))
                )}
            </Row>
        </Col>
      </Row>
    </div>
  );
}

export default TeamRecords;
