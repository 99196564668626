import React, { useState } from "react";
import { ROUTES } from './constants';
import { ACCOUNT_ENUM } from "./Route";
import PrimeTime from "./PrimeTime";
import TournamentSearch from "./TournamentSearch";
import TeamSearch from "./TeamSearch";
import TeamView from "./TeamView";
import TournamentView from "./TournamentView";
import Privacy from "./Privacy";
import Terms from "./TermsOfService";


export default function PrimeTimeIndex() {
    const [state, setState] = useState({
        data: {},
        route: ACCOUNT_ENUM.PRIMETIME
    })

    const handlePageChange = (page, data = {}) => {
        setState(prev => {
            return {
                ...prev,
                data: data,
                route: page
            }
        })
    }

    switch (state.route) {
        case ROUTES.PRIMETIME:
            return <PrimeTime setPage={handlePageChange} />
        case ROUTES.TOURNAMENT_SEARCH:
            return <TournamentSearch setPage={handlePageChange} />
        case ROUTES.TEAM_SEARCH:
            return <TeamSearch setPage={handlePageChange} />
        case ROUTES.TEAM_VIEW:
            return <TeamView setPage={handlePageChange} />
        case ROUTES.TOURNAMENT_VIEW:
            return <TournamentView setPage={handlePageChange} />
        case ROUTES.PRIVACY:
            return <Privacy setPage={handlePageChange} />
        case ROUTES.TERMS:
            return <Terms setPage={handlePageChange} />
        default:
            return <PrimeTime setPage={handlePageChange} />

    }
}