import React, { useState } from 'react'

import { ROUTES } from "./Route";
import TeamScreen from './Team';
import ViewTeamScreen from './ViewTeam';

export default function Teams() {

  const [data, setData] = useState({
    page: ROUTES.TEAM,
    teamId: '',
    details: {}
  })


  const setPage = (route, data = {}) => {
    setData({
      page: route,
      teamId: data.teamId ?? '',
      details: data.details ?? {}
    })
  }


  switch (data.page) {
    case ROUTES.TEAM:
      return <TeamScreen setPage={setPage} />
    case ROUTES.VIEW_TEAM:
      return <ViewTeamScreen setPage={setPage} teamId={data.teamId} details={data.details} />
    default:
      return <TeamScreen />
  }


}
