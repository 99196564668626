import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import MagicContent from "../Tournaments/MagicContent";
import CreateTeamCard from "../Tournaments/Components/CreateTeamCard";
import { ROUTES } from "./Route";
import { BackButton } from "../../Components/CustomComponents";


const MagicBoxScreen = ({ setPage }) => {
  const [playerAge, setPlayerAge] = useState(undefined);
  const [selectedPlayer, setSelectedPlayer] = useState([]);
  const [selectedSport, setSelectedSport] = useState('Baseball');
  const [selectedClass, setSelectedClass] = useState('B');


  const handleBackButtonClick = () => {
    setPage(ROUTES.PROFILE);
  }

  return (
    <div style={{ width: '100%', color: "black"}}>
      <BackButton isRelative={true} handleBackButton={handleBackButtonClick} />
      <Row>
        <Col md={12} lg={6}>
          <div style={{ color: 'white', }}>
            <CreateTeamCard playerAge={playerAge} selectedPlayer={selectedPlayer} handleClose={handleBackButtonClick} setSelectedSport={setSelectedSport} selectedSport={selectedSport} setSelectedClass={setSelectedClass} selectedClass={selectedClass}/>
          </div>
        </Col>
        <Col md={12} lg={6}>
          <MagicContent playerAge={playerAge} setPlayerAge={setPlayerAge} selectedPlayer={selectedPlayer} setSelectedPlayer={setSelectedPlayer} selectedSport={selectedSport} />
        </Col>
      </Row>
    </div>
  );
};

export default MagicBoxScreen;
