export const BASE_URL = process.env.REACT_APP_API_DOMAIN;

export const WEB_ROUTES = {
    ACCOUNT: '/account',
    DASHBOARD: '/dashboard',
    MY_TOURNAMENTS: '/my-tournaments',
    MY_TEAM: '/my-team',
    // MESSAGE: 'messages',
    PAYMENT_METHOD: '/payment-methods',
    SETTING: '/settings',
    PROFILE: '/profile',
    PRIME_TIME: '/prime-time',
    TOURNAMENT:'/tournament',
    PRIVACY: '/privacy-policy',
    TERMS: '/terms-of-service',
}

export const BREAKPOINTS = {
    MOBILE: 991
}
export const ROLES = {
    VIEWER: 'VIEWER',
    COACH: 'COACH',
    PLAYER: 'PLAYER',
}

export const API_ROUTES = {
    LOGIN: 'api/user/login',
    CREATE_USER: 'api/user',
    UPDATE_USER: 'api/user',
    FORGOT_PASSWORD: 'api/user/forgot-password',
    VERIFY_OTP_REGISTER: 'api/user/verify-otp/register',
    GET_PROFILE: 'api/user',
    GET_TOURNAMENTS: 'api/tournament',
    GET_TEAMS: 'api/team',
}

export const CONTAINER_KEYS = {
    SPLASH_SCREEN: 'splash',
    SPLASH_SCREEN_IMAGE: 'splash-image'
}

export const IMAGE_TYPES = '*.png,*jpeg,*.jpg,image/*'

export const FIELDS_MAPPING = {
    "current_password": "Current password",
    "new_password": "New password",
    "confirm_password": "Confirm password",
}


export const SPORTS_TYPE = {
    BASEBALL: "Baseball",
    FAST_PITCH: "Fast Pitch"
}

export const PASSWORD_PATTERN = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/