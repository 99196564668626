import { Bracket, RoundProps, Seed, SeedItem, SeedTeam, RenderSeedProps } from 'react-brackets';
import Medal from '../../../../src/assets/images/Medal.png';
import './TreeComponent.css'

const TreeComponent = ({ data = {} }) => {
    // Left bracket data doesn't change
    const CustomSeed = ({ seed, breakpoint, roundIndex, seedIndex }: RenderSeedProps) => {
        // breakpoint passed to Bracket component
        // to check if mobile view is triggered or not
        const winnerClassName = seed.teams.length === 1 ? 'winner' : '';
        // mobileBreakpoint is required to be passed down to a seed
        return (
            <Seed mobileBreakpoint={breakpoint} style={{ fontSize: 12 }} >
                <SeedItem className={`seed ${winnerClassName}`}>
                    <div >
                        <SeedTeam>{seed.teams[0]?.name || 'NO TEAM '}</SeedTeam>
                        {winnerClassName ? '' : <SeedTeam>{seed.teams[1]?.name || 'NO TEAM '}</SeedTeam>}

                    </div>
                </SeedItem>
                {/* <div>{seed.date}</div> */}

            </Seed>
        );
    };
    return (
        <>
            <div className='mt-4' style={{
                overflowX: 'scroll',
                width: '100%',
                display: 'flex',
                flexDirection: 'row'
            }}>
                {/* Left Bracket */}
                <div className='bracket left-bracket mt-5'>
                    <Bracket
                        rounds={data['A']}
                        rtl={false}
                        roundClassName='bracket-custom'
                        renderSeedComponent={CustomSeed}
                    // ... other props
                    />
                </div>

                {/* Trophy */}
                <span className='medal-img img-fluid d-flex align-items-center'>
                    <img src={Medal} alt="Trophy" />
                </span>

                {/* Right Bracket */}
                <div className='bracket right-bracket mt-5'>
                    <Bracket
                        rounds={data['B']}
                        rtl={true}
                        roundClassName='bracket-custom-left'
                        renderSeedComponent={CustomSeed}
                    // ... other props
                    />
                </div>
            </div>
        </>
    );
};

export default TreeComponent;