import React, { useState, useEffect } from "react";
import { TeamAPI } from "../../Api/Team";
import { useQuery } from "react-query";
import { Toast } from "../../Hooks/useToast.js";
import { useNavigate, useParams } from "react-router-dom";
import { WEB_ROUTES } from "../../constants";
import { baseRoute } from "../../Helper";
import Default from '../../assets/images/default.jpg';
import styles from "../../Components/Layouts/styles/PrimeTimeLayout.module.scss";


export default function TeamView() {
    const { slug } = useParams();
    const teamAPI = TeamAPI();
    const [players, setPlayers] = useState([]);
    const navigate = useNavigate();

    // Memoized query to get the team
    const { data: team = {}, isError, isLoading } = useQuery({
        queryKey: ['team', slug],
        queryFn: () => teamAPI.getTeamById(slug),
        select: (teamData) => {
            // Preprocess the team data here
            const processedPlayers = teamData.data.team_members?.map(member => ({
                name: member?.name,
                age: member?.age,
                image_url: member?.image_url,
            })) || [];
            return { ...teamData.data, players: processedPlayers };
        },
        onError: (err) => Toast(err.message, 'error', false),
        refetchOnMount: false,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        // Update players state only when team.players is available
        if (team && team.players && team.players.length > 0) {
            setPlayers(team.players);
        }
    }, [team]);

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error loading team data</div>;

    return (
        <div className={styles.sectionContainer}>
            <div className="container mb-2">
                <div className="row">
                    <h3>{team.name}</h3>
                    <div className="col-md-4 col-sm-12 mb-2">
                        <img src={team.image_url || Default} alt={team.name} style={{ maxWidth: '100%', height: '250px', objectFit: 'contain', borderRadius: '4px' }}/>
                        <br />
                    </div>
                    <div className="col-md-8 col-sm-12">
                        <div className="row">
                            <div className={`col-sm-12 ${styles.infoCard} card`}>
                                <div className="row card-body p-2">
                                    <div className="col-12">
                                        <h5 className="card-title text-light text-center mb-2">Team Info</h5>
                                    </div>
                                    <div className={`col-md-12 ${styles.infoDiv}`}>
                                        <ul className="list-group">
                                            <li className="list-group-item d-flex justify-content-between">
                                                <strong>Sport:</strong> <span>{team.sports_type}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between">
                                                <strong>Age Group:</strong> <span>{team.group_title}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between">
                                                <strong>Coach:</strong> <span>{team.coach_name}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between">
                                                <strong>Class:</strong> <span>{team.class}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between">
                                                <strong>Record:</strong> <span>{team.record}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <h3>Players</h3>
                <table className="table align-middle mb-0">
                    <thead className="bg-primary">
                        <tr>
                            <th>Name</th>
                            <th>Age</th>
                        </tr>
                    </thead>
                    <tbody>
                        {players.length > 0 ? (
                            players.map((player, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className="d-flex align-items-center">
                                        <img
                                            src={player.image_url || Default}
                                            alt="player image"
                                            style={{ width: '55px', height: '45px' }}
                                            className="rounded-circle"
                                            />
                                        <div className="ms-3">
                                            <p className="fw-bold mb-1">{player.name}</p>
                                        </div>
                                        </div>
                                    </td>
                                    <td>
                                        <p className="fw-normal mb-1">{player.age}</p>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2" style={{ textAlign: 'center' }}>No players available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
