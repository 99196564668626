import React, { useState } from "react";
import { Navigate, useParams, useLocation } from "react-router-dom"; // Ensure you're using React Router for navigation
import { Container, Row, Col } from "react-bootstrap";
import HomeNav from "../../Components/Layouts/HomeNav";
import PrimeTimeLayout from "../../Components/Layouts/PrimeTimeLayout";
import { ShadowContainer } from '../../Components/CustomComponents';
import styles from "./styles/PrimeTime.module.scss";
import TeamSearch from "./TeamSearch"; 
import TeamView from "./TeamView"; 
import TournamentSearch from "./TournamentSearch"; 
import TournamentView from "./TournamentView";  
import Prime from "./Prime"; 
import Privacy from "./Privacy";
import Terms from "./TermsOfService";

export default function PrimeTime({ setPage, slug }) {
    const location = useLocation();
    const [view, setView] = useState("");
    const isViewingTournament = location.pathname.includes("/tournament/");
    const isViewingTeam = location.pathname.includes("/team/");
    const isViewingTourSearch = location.pathname.includes("/tournaments/");
    const isViewingTeamSearch = location.pathname.includes("/teams/");
    const isViewingPrivacy = location.pathname.includes("/privacy-policy");
    const isViewingTerms = location.pathname.includes("/terms-of-service");

    
    const handleViewChange = (newView) => {
        setView(newView);
    };
    return (
        <div>
            <PrimeTimeLayout>
                {isViewingTournament ? (
                    <TournamentView tournamentSlug={slug}/>
                ) : isViewingTeam ? (
                    <TeamView teamSlug={slug}/>
                ) : isViewingTourSearch ? (
                    <TournamentSearch/>
                ) : isViewingTeamSearch ? (
                    <TeamSearch />
                ): isViewingPrivacy ? (
                    <Privacy />
                ):  isViewingTerms ? (
                    <Terms />
                ): <Prime handleViewChange={handleViewChange}/>}
            </PrimeTimeLayout>
        </div>
    );
}
