import React, { useRef, useState } from 'react';
import cssStyles from "../index.module.css";
import { Button, CardTitle, Input } from '../../../Components/CustomComponents';
import { Form } from 'react-bootstrap';
import { getAgeFromDateOfBirth } from '../../../Helper';


const GetRegisterForm = ({ isSubmitted, handleRegistration }) => {
  const ageRef = useRef()
  const [data, setData] = useState({
    name: "",
    dateOfBirth: "",
    age: "",
    gender: "",
    sports_type: ""
  })

  const sportsOptions = [
    { value: 'Baseball', label: 'Baseball' },
    { value: 'Fast Pitch', label: 'Fast Pitch' },
  ];

  const genderOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' }
  ];


  const handleInputChange = (name, value) => {
    setData(prev => {
      return {
        ...prev,
        [name]: value
      }
    })
  };

  const handleDateOfBirthChange = (value) => {
    setData(prev => {
      return {
        ...prev,
        dateOfBirth: value,
      }
    })
    ageRef.current.value = getAgeFromDateOfBirth(value)
  }

  const handleSubmit = (e) => {
    handleRegistration(data);
  }

  return (
    <div >

      <div style={{ maxWidth: '450px' }}>

        <CardTitle
          text='Full name of your Kid'
          classes='fs-7 mb-1'
        />

        <Input
          classes="mt-2 mb-3"
          placeholder="Enter Full Name"
          name="name"
          value={data?.name}
          onChange={handleInputChange} />

        <CardTitle
          text='Date of Birth of your Kid'
          classes='fs-7 mb-1'
        />
        <div className='mt-2 mb-3'>

          <Form.Control
            className={`${cssStyles.datePicker}`}
            name="dateOfBirth"
            type="date"
            placeholder='Select Date'
            value={data?.dateOfBirth}
            onChange={(e) => handleDateOfBirthChange(e.target.value)}
            onKeyDown={(e) => e.preventDefault()}
            max={new Date().toISOString().slice(0, 10)}
          />
        </div>

        <CardTitle
          text='Age of your Kid'
          classes='fs-7 mb-1'
        />
        <Input
          classes="mt-2 mb-3"
          placeholder="Enter age"
          name="age"
          disabled={true}
          ref={ageRef}
          readOnly={true}
        />

        <CardTitle
          text="Gender of your Kid"
          classes="fs-7 mb-1"
        />
        <div className='mt-2 mb-3'>
          <Form.Control
            className={`${cssStyles.datePicker}`}
            name="gender"
            as="select"
            value={data?.gender}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          >
            <option value="" disabled selected>Select Gender</option>
            {genderOptions.map((option, index) => {
              return <option key={index} value={option.value}>{option.label}</option>
            })}
          </Form.Control>
        </div>

        <CardTitle
          text="Sports Type"
          classes="fs-7 mb-1"
        />
        <div className='mt-2 mb-3'>
          <Form.Control
            className={`${cssStyles.datePicker}`}
            as="select"
            name="sports_type"
            value={data?.sports_type}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          >
            <option value="" disabled selected>Select Sport</option>
            {sportsOptions.map((option, index) => {
              return <option key={index} value={option.value}>{option.label}</option>
            })}
          </Form.Control>
        </div>

        <Button
          classes={` mt-2 fs-6`}
          text="Register your Kid"
          isSubmitted={isSubmitted}
          onClick={handleSubmit}
        />

      </div>
      <p></p>
    </div>
  )
}

export default GetRegisterForm
