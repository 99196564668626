import React, { useState, useEffect } from 'react';
import { useQuery } from "react-query";
import { CardTitle, SubTitle, Button } from '../../Components/CustomComponents';
import { TournamentAPI } from "../../Api/Tournament.js";
import { useSelector } from "react-redux";
import cssStyles from "./index.module.css";
import Default from "../../assets/images/default.jpg";
import { Card, Col, Image, Row, Stack, Form } from "react-bootstrap";
import CustomBadge from '../../Components/Badges/CustomBadge';
import TimeBadge from '../../Components/Badges/TimeBadge.js';
import { FaMapMarkerAlt } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { baseRoute } from '../../Helper/index.js';
import { WEB_ROUTES } from '../../constants.js';

const TeamTournaments = ({ team_slug = '' }) => {
    const tournamentAPI = TournamentAPI();
    const navigate = useNavigate();
    const user_type = useSelector(state => state.default.route);
    const [year, setYear] = useState(new Date().getFullYear());
    const years = [
        new Date().getFullYear() + 1,
        new Date().getFullYear(),
        new Date().getFullYear() - 1,
        new Date().getFullYear() - 2,
        new Date().getFullYear() - 3
    ];
    const [tournaments, setTournaments] = useState([]);
    const [tempData, setTempData] = useState([]);

    const { data, isError, isLoading } = useQuery({
        queryKey: [user_type + 'tournament/team/' + team_slug, year],
        queryFn: async () => await tournamentAPI.getTournamentsForTeam(team_slug, year),
        select: (data) => data,
        onSuccess: (data) => {
            setTournaments(data.data);
        }
    });


    const handleViewTournament = (idx) => {
        navigate(`${WEB_ROUTES.TOURNAMENT}/${tournaments[idx].slug}`);
    }

    const handleYearChange = (value) => {
        setYear(value);
    }

    return (
        <div>
            <Row>
                <Col md={12} className='m-2'>
                    <Row>
                        <Col md={10}>
                            <SubTitle
                                text='Team Tournaments'
                                classes='mb-4 mt-2'
                            />
                        </Col>
                        <Col md={2}>
                            <Form.Control
                                as="select"
                                className="form-select"
                                name="year"
                                value={year}
                                onChange={(e) => handleYearChange(e.target.value)}
                            >
                                {years.map((year, idx) => (
                                    <option key={idx} value={year}>{year}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Row>
                </Col>
                <Col md={12}>
                    <Row>
                        {tournaments.length === 0 ? (
                            <p>No tournaments found.</p>
                        ) : (
                            tournaments.map((record, idx) => (
                                <Col key={idx} lg={4}>
                                    <div
                                        className={cssStyles.cardStyle}
                                        style={{ backgroundImage: `url(${record.media_url})` }} >
                                        <Row>
                                            <Col
                                                xs={2}
                                                className="d-flex"
                                                style={{ alignItems: "start", justifyContent: "center" }}
                                            >
                                                <Stack
                                                    direction="horizontal"
                                                    gap={1}
                                                    style={{ alignItems: "start" }}
                                                >
                                                    <Image
                                                        src={Default}
                                                        roundedCircle
                                                        className={cssStyles.avatarImage}
                                                    />
                                                </Stack>
                                            </Col>

                                            <Col xs={10}>
                                                <Row>
                                                    <Col
                                                        xxl={{ order: 1, span: 8 }}
                                                        xl={{ order: 1, span: 7 }}
                                                        lg={{ order: 2, span: 12 }}
                                                        md={{ order: 1, span: 9 }}
                                                        sm={{ order: 1, span: 8 }}
                                                        xs={{ order: 2, span: 12 }}
                                                    >
                                                        <CardTitle
                                                            text={record.name}
                                                            classes="mt-1 fs-3 lc-1"
                                                        />

                                                        <div className="d-flex">
                                                            <span className="me-1">
                                                                <FaMapMarkerAlt />
                                                            </span>
                                                            <Card.Subtitle className="mb-2 lc-1">
                                                                {record.location}
                                                            </Card.Subtitle>
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        xxl={{ order: 2, span: 4 }}
                                                        xl={{ order: 2, span: 5 }}
                                                        lg={{ order: 1, span: 12 }}
                                                        md={{ order: 2, span: 3 }}
                                                        sm={{ order: 2, span: 2 }}
                                                        xs={{ order: 1, span: 2 }}
                                                        className="d-flex"
                                                        style={{
                                                            alignItems: "start",
                                                        }}
                                                    >
                                                        <TimeBadge time={record.start_date} />
                                                    </Col>
                                                </Row>
                                                <div>
                                                    <Stack direction="horizontal" gap={2}>
                                                        <CustomBadge text={record.sports_type} />
                                                    </Stack>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Button
                                            classes={`${cssStyles.cardBtn} mt-4`}
                                            text="View Tournament Details"
                                            onClick={() => handleViewTournament(idx)}
                                        >
                                            View Tournament Details
                                        </Button>
                                    </div>
                                </Col>
                            ))
                        )}
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default TeamTournaments;