import React, { useState } from "react";
import TeamSelection from "./Components/TeamSelection";
import Tabs from "../../Components/Tabs/Tabs";
import { Col, Row } from 'react-bootstrap';
import styles from '../Tournaments/index.module.css';
import { useEffect } from "react";

import _ from 'lodash';
import { AgeGroupAPI } from '../../Api/AgeGroup';
import { useQuery } from 'react-query';
import { Toast } from '../../Hooks/useToast';
import { Description, EmptyContainer, Input, Loader } from "../../Components/CustomComponents";

const MagicContent = ({ playerAge, setPlayerAge, selectedPlayer, setSelectedPlayer, selectedSport, selectedClass }) => {
    const ageGroupAPI = AgeGroupAPI()
    const QUERY_KEY = 'user/age-group';
    const [search, setSearch] = useState('');

    const inlineItemStyles = {
        background: '#222232',
        margin: '5px',
        padding: '10px 20px',
    };

    const [playerCount, setPlayerCount] = useState(0);

    const { data: ageGroupData, isError: isAgeGroupError, isLoading: isAgeGroupLoading } = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: async () => await ageGroupAPI.get(),
        select: (data) => data.data,
        onSuccess: (data) => {
            if (!_.isEmpty(data) && _.isUndefined(playerAge)) {
                setPlayerAge(data[0].value)
            }
        },
        onError: (err) => Toast(err.message, 'error', false),
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        setPlayerCount(0)
        setSelectedPlayer([])
    }, [playerAge])


    return (
        <div className="mt-3">
            <div className="d-flex align-items-center">
                <h2 className="fw-5 text-dark">Roster’s Selection</h2>
                <span className={styles.counter}> {playerCount} Player Selected </span>
            </div>

            <p className="text-dark">
                Select the players you want to add to your roster.
            </p>

            <p className="text-dark m-0 fw-bold">
                Team Age Group
            </p>
            {isAgeGroupLoading ? <Loader /> :
                (isAgeGroupError || _.isEmpty(ageGroupData)) ? <EmptyContainer component={<Description text="Age group not found" />} /> :
                    <>
                        <Row>
                            <Col className='p-2'>
                                <Tabs tabs={ageGroupData} current={playerAge} onChange={setPlayerAge} itemStyle={inlineItemStyles} containerStyles={{ width: 'fit-content' }} />
                            </Col>
                            <Col className="p-2">
                            <Input 
                                placeholder="Search Player"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            </Col>
                        </Row>
                        <TeamSelection
                            key={playerAge}
                            ageGroup={playerAge}
                            sport={selectedSport}
                            class={selectedClass}
                            setPlayerCount={setPlayerCount}
                            selectedItems={selectedPlayer}
                            setSelectedItems={setSelectedPlayer}
                        />
                    </>
            }
        </div>
    )
}

export default MagicContent
