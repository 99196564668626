import { useSelector } from "react-redux";
import { Axios } from "../Hooks/useAxiosInstance";


export const MatchAPI = () => {

    const { route, user } = useSelector(state => state.default)

    const apis = ({ route, api_token }) => ({
        getUpcoming: async () => await getUpcomingRecord(api_token),
        getTopLive: async () => await getTopLiveRecord(api_token),
        getTournamentMatches: async (id) => await getTournamentMatchesRecord(id, api_token),
    })

    async function getUpcomingRecord(api_token) {
        const url = "user/match/upcoming";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getTopLiveRecord(api_token) {
        const url = "user/match/live/top";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getTournamentMatchesRecord(id, api_token) {
        const url = "user/tournament/age-group/" + id + "/match";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    return apis({ route: route, api_token: user.api_token });
}

