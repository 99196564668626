import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentFormModal from "./PaymentForm";
import styles from "../index.module.css";

const stripePromise = loadStripe(
  "pk_test_51P6zI705fjokOasgPeZenq4MYgDwD2bLGBTbi7Af2iZH5O3eHT7zU2EzdyO8JlyD71yWcHwwAlU4AF33uEr9EK9U00a4Fagfxt"
);
const PaymentProcess = ({
  paymentPopup,
  setPaymentProcess,
  handleCheckout = () => { }
}) => {

  return (
    <>
      <Elements stripe={stripePromise}>
        <Modal
          className={styles.paymentPopup}
          show={paymentPopup}
          onHide={() => setPaymentProcess(false)}
          size="md"
          centered
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Body
            className={styles.modalBody}
            style={{
              backgroundColor: "#0D0D1C",
            }}
          >
            <div>
              <div className={`${styles.paymentTopHeading} text-center mb-5`}>
                <h2
                  style={{
                    color: "black",
                    fontFamily: "Lato",
                    fontWeight: 700,
                    color: "white",
                  }}
                >
                  Payment Procedure
                </h2>
              </div>

              <div className="">
                <PaymentFormModal
                  handleCheckout={handleCheckout}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Elements>
    </>
  );
};

export default PaymentProcess;
