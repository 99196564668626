import React, { useState } from 'react';

import styles from './index.module.scss';

import { Button, CardTitle, Description, SubTitle } from '../../Components/CustomComponents';

import { Spinner, Row, Col } from 'react-bootstrap';
// import { CiShare2 } from "react-icons/ci";
import { FaBan, FaMapMarkerAlt } from "react-icons/fa";
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { UserAPI } from "../../Api/User";
import ModalContainer from '../../Components/ModalContainer';
import { setUser } from '../../Features/DefaultReducer';
import { Toast } from '../../Hooks/useToast';
import { ROUTES } from './Route';
import AddKid from './components/AddKid';
import Kid from './components/Kid';
import Team from './components/Team';
import TeamView from './components/TeamView';
// import Ticket from './components/Ticket';
import TicketDetails from './components/TicketDetails';
import Tabs from "../../Components/Tabs/Tabs";
import { set } from 'lodash';


export default function ProfileScreen({ setPage }) {
    const useAPI = UserAPI()
    const queryClient = useQueryClient();
    const dispatch = useDispatch()
    const { isViewer: IS_VIEWER, isCoach: IS_COACH, user } = useSelector(state => state.default);
    const [modal, setModal] = useState(false);
    const [showAddKidModal, setShowAddKidModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(true)
    const [details_slug, setDetailSlug] = useState('')
    const [key, setKey] = useState("team");

    const coachMutation = useMutation(useAPI.upgrade, {
        onSuccess: (data) => {
            setIsSubmitted(true)
            Toast(data.message, 'success', false)
        },
        onError: (err) => {
            setIsSubmitted(true)
            Toast(err.message, 'error', false)
        }
    });

    const array = [
        {
            value: 'team',
            label: 'My Teams'
        },
        {
            value: 'kids',
            label: 'My Kids'
        },
    ]

    const handleModalClose = () => {
        setModal(false)
    }

    const openAddKidModal = () => {
        setShowAddKidModal(true);
    }

    const closeAddKidModal = () => {
        setShowAddKidModal(false);
    }

    const handleMagicBox = () => {

        setPage(ROUTES.MAGIC_BOX);
    };

    const handleCoachRequest = () => {
        setDetailSlug('')
        setIsSubmitted(false)
        coachMutation.mutate()
    }

    const tabContent = () => {
        switch (key) {
          case "team":
            return <Team team_slug={details_slug} setData={setDetailSlug} />
          case "kids":
            return <Kid isAddKid={(IS_VIEWER || IS_COACH)} isBanned={user.is_banned} handleAddKid={(IS_VIEWER || IS_COACH) ? openAddKidModal : null} />;
          default:
            return <Team team_slug={details_slug} setData={setDetailSlug} />;
        }
    };

    return (
        <div className={styles.Profile}>
            {showAddKidModal ?
                <ModalContainer
                    handleClose={closeAddKidModal}
                >
                    <AddKid handleClose={closeAddKidModal} />
                </ModalContainer>
                :
                ''
            }

            <div className={styles.row}>
                <div
                    className={`${styles.col} ${styles.col1}`}
                    md={5}
                >
                    <SubTitle
                        text={'Profile'}
                    />
                    <div
                        className={`${styles.userContainer} mt-4 mb-4`}
                    >
                        <img
                            src={user.image_url??'/images/slide-diamond.png'}
                            // src={'/images/slide-diamond.png'}
                            alt='User Profile'
                            className={styles.image}
                        />
                        <div className={styles.nameContainer}>
                            <div className={`d-flex flex-row align-items-center`}>
                                <div className='d-flex flex-column'>
                                    <CardTitle
                                        classes='text-dark'
                                        text={user.firstname + ' ' + user.lastname}
                                    />
                                    <Description
                                        text={user.email}
                                        classes={`${styles.email} text-dark fs-8`}
                                    />
                                </div>
                                {!user.is_banned ? "" :
                                    <div>
                                        <FaBan style={{ color: "#E95B5B", fontSize: "2rem", marginLeft: "10px" }} />
                                    </div>
                                }
                            </div>

                            {
                                IS_VIEWER ?
                                    !isSubmitted ? <span className={styles.tag} >
                                        <Spinner size="sm" style={{ fontSize: "10px" }} />
                                    </span> :
                                        <span className={styles.tag} onClick={handleCoachRequest}>
                                        {/* <span className={styles.tag} > */}
                                            Request to be a coach
                                        </span>
                                    :
                                    <span className={styles.tag}>
                                        Coach
                                    </span>
                            }

                        </div>

                    </div>
                    {IS_VIEWER ? <Kid isAddKid={(IS_VIEWER || IS_COACH)} handleAddKid={(IS_VIEWER || IS_COACH) ? openAddKidModal : null} />:
                        IS_COACH ?
                            <div>
                                {
                                    !user.is_banned ?
                                        <Button
                                            classes={`${styles.panelBtn} ${styles.orangeBg} mb-2`}
                                            text="Roster’s Selection"
                                            onClick={handleMagicBox}
                                        ></Button>
                                        : ""
                                    //  <SubTitle text='Banned by the admin' classes='text-center fs-4 mb-5 text-secondary' />
                                }
                                <Row className='mb-2'>
                                    <Col xs={12} >
                                        <div className={styles.beforeTabs}>
                                            <Tabs tabs={array} current={key} onChange={setKey} />
                                        </div>
                                    </Col>
                                </Row>
                                {tabContent()}
                            </div>
                            : ""
                    }
                </div>


                <div
                    className={`${styles.col} ${styles.teamInfoSection}`}
                    md={7}
                >
                    {
                        !details_slug ? "" :
                            IS_VIEWER ?
                                <TicketDetails ticket_slug={details_slug} />
                                :
                                <TeamView key={details_slug} team_slug={details_slug} />
                    }


                </div>

            </div >

        </div >
    )
}