import { useSelector } from "react-redux";
import { Axios } from "../Hooks/useAxiosInstance";

export const PaymentAPI = () => {
  const { user } = useSelector((state) => state.default);

  const apis = () => ({
    post: async (data, api_token) => await postRecord(data, api_token),
  });

  async function postRecord(data, api_token) {
    const url = "user/stripe/create-customer";
    const option = {
      headers: {
        Authorization: api_token,
        "Content-Type": "application/json",
      },
    };
    return await Axios.post(url, data, option);
  }

  return apis();
};

export const ConfirmPayment = () => {
  const { user } = useSelector((state) => state.default);

  const apis = () => ({
    post: async (data, api_token) => await postRecord(data, api_token),
  });

  async function postRecord(data, api_token) {
    const url = "user/stripe/create-payment";
    const option = {
      headers: {
        Authorization: api_token,
        "Content-Type": "application/json",
      },
    };
    return await Axios.post(url, data, option);
  }

  return apis();
};
