import React, { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

/**Import Assets */
import styles from "./styles/ProtectedLayout.module.scss";
// import cssStyles from './styles/notification.module.css';


/**Import Custom Component */
import { WEB_ROUTES } from "../../constants";
import { logout } from "../../Features/DefaultReducer";
import { baseRoute } from "../../Helper";
import { GiHamburgerMenu } from "react-icons/gi";

import { useDispatch, useSelector } from "react-redux";
import NotificationModal from "../Modals/NotificationModal";
// Icons
import { IoIosSettings } from "react-icons/io";
import { MdDashboard } from "react-icons/md";
import { TbLogout2, TbTournament, TbUsers } from "react-icons/tb";
import { TiMessages } from "react-icons/ti";
import { useQueryClient } from "react-query";

export default function ProtectedLayout({ isSearchBar, isSettingButton, selectedRoute, children }) {
    const queryClient = useQueryClient()
    const { user, isPlayer: IS_PLAYER } = useSelector(state => state.default)
    const [collapsed, setCollapsed] = useState(window.innerWidth > 991)
    const [notificationModal, setNotificationModal] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const items = useMemo(() => {
        return [
            {
                label: "Dashboard",
                icon: <MdDashboard />,
            },
            {
                label: "My Team",
                icon: <TbUsers />,
            },
            // {
            //     label: "My Tournaments",
            //     icon: <TbTournament />,
            // },
            // !IS_PLAYER && {
            //     label: "Payment Methods",
            //     icon: <MdOutlinePayment />,
            // },
            {
                label: "Settings",
                icon: <IoIosSettings />,
            },

        ].filter(Boolean)
    }, [IS_PLAYER])

    const handleToggle = () => {
        setCollapsed(!collapsed)
    }

    const handleMenuClose = () => {
        setCollapsed(false)
    }

    const handleViewProfile = () => {
        navigate(WEB_ROUTES.PROFILE)
    }

    const handleLogout = () => {
        dispatch(logout())
        queryClient.clear()
        navigate(WEB_ROUTES.ACCOUNT);
    }

    const handleNotificationModalClose = () => {
        setNotificationModal(false)
    }

    const handleNotificationModalOpen = () => {
        setNotificationModal(true)
    }

    return (
        <div className={styles.ProtectedLayout}>
            <div className={collapsed ? styles.background : ''} onClick={handleMenuClose}></div>
            <div
                className={`${styles.menu} ${collapsed ? `${styles.collapsed} visible` : styles.notCollapsed}`}
                id="sider"
            >
                <div className={`${styles.logo} ${!collapsed ? styles.notCollapsed : ''}`} to={`${process.env.REACT_APP_DOMAIN_DIR}/dashboard`}>
                
                    <img
                        src={user.image_url}
                        alt="User Profile"
                        className={collapsed ? styles.imageCollapsed : styles.imageNotCollapsed}
                    />
                    <p
                        className={styles.name}
                    >
                        {user.firstname + ' ' + user.lastname}
                    </p>
                    {IS_PLAYER ? "" :
                        <button
                            className={`${styles.profileButton} ${(selectedRoute === 5) ? 'bg' : ''}`}
                            onClick={handleViewProfile}
                        >
                            View Profile
                        </button>
                    }
                </div>

                {items.map(({ icon, label }, index) => {
                    return <Link to={`/${label.toLowerCase().replace(/[^A-Z0-9]/ig, "-")}`} className={`${styles.item} ${index === selectedRoute ? styles.active : ""}`} key={index}>
                        <span style={{ fontSize: '20px', display: 'flex' }}> {icon} </span>
                        {collapsed ? <p>{label}</p> : ''}

                    </Link>
                })}

                <div
                    className={styles.item}
                    onClick={handleLogout}
                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                >
                    <span style={{ fontSize: '20px', display: 'flex' }}>
                        <TbLogout2 />
                    </span>
                    {collapsed ? <p>Logout</p> : ''}
                </div>
            </div>
            <div 
                className={`${styles.hamburgerButton}`} 
                onClick={handleToggle}
            >
                <GiHamburgerMenu className={styles.hamburgerIcon} />
            </div>
            <div className={styles.routeContent}>
                <div className={styles.content}>
                    {
                        notificationModal ?
                            <NotificationModal handleNotificationModalClose={handleNotificationModalClose} />
                            : ''
                    }

                    {children}
                </div>
                
            </div>
        </div >
    )


}
