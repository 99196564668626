import React, { useEffect, useState } from "react";
import cssStyles from "./RegisterTeam.module.css";
import { FaCircleXmark } from "react-icons/fa6";
import { Col, Row } from "react-bootstrap";
import { useQuery, useQueryClient } from "react-query";
import _ from "lodash"
import { TeamAPI } from "../../../Api/Team";
import { RegisterAPI } from "../../../Api/Register";
import { Toast } from "../../../Hooks/useToast";
import TournamentBannerForModal from "../../../Components/Cards/TournamentBannerForModal";
import CheckOutSummary from "./CheckOutSummary";
import TeamList from "./TeamList";
import { CardTitle, EmptyContainer, Loader } from "../../../Components/CustomComponents";
import { WEB_ROUTES } from "../../../constants";
import { Link } from "react-router-dom";
import { useMutation } from 'react-query';

export default function RegisterTeam({ data = {}, handleClose = () => { }, TOURNAMENT_DETAIL_QUERY_KEY = '' }) {
  const QUERY_KEY = 'team/'+data.slug+'unregister'
  const teamAPI = TeamAPI()
  const registerAPI = RegisterAPI()
  const queryClient = useQueryClient()
  const { data: teamData, isError, isLoading } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => await teamAPI.getUnregisterTeam(data.slug),
    select: (data) => data.data,
    onSuccess: (data) => {
      if (!_.isEmpty(data)) {
        setSelectedTeam(0)
      }
    },
    onError: (err) => Toast(err.message, 'error', false),
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })
  const [selectedTeam, setSelectedTeam] = useState(undefined);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const registerMutation = useMutation(registerAPI.post, {
    onSuccess: (data) => {
      handleClose()
      queryClient.invalidateQueries({ queryKey: [TOURNAMENT_DETAIL_QUERY_KEY], refetchActive: true, refetchInactive: true })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY], refetchActive: true, refetchInactive: true })
      setIsSubmitted(false)
    },
    onError: (err) => {
      Toast(err.message, 'error', false)
      setIsSubmitted(false)
    }
  });

  const handleCheckOut = (card_token) => {
    if (_.isUndefined(selectedTeam)) return
    const payload = {};
    payload.stripe_payment_id = card_token;
    payload.tournament_slug = data.slug;
    payload.team_slug = teamData[selectedTeam].slug;
    registerMutation.mutate(payload)
  }
  const handleCheckOut2 = () => {
    if (_.isUndefined(selectedTeam)) return
    const payload = {};
    payload.tournament_slug = data.slug;
    payload.team_slug = teamData[selectedTeam].slug;
    registerMutation.mutate(payload)
  }

  return (
    <div className={cssStyles.modalContainer}>
      <div className={cssStyles.container}>

        <div className="d-flex align-items-center w-100 justify-content-between mb-4">
          <p className="fs-4 fw-medium m-0">Get your team register</p>
          <FaCircleXmark
            onClick={handleClose}
            style={{ cursor: "pointer", marginRight: "10px" }}
          />
        </div>

        <div
          className={cssStyles.container}
        >
          <Row style={{ width: "100%", marginTop: "20px" }}>
            <Col md={6}>
              {isLoading ? <Loader /> :
                (isError || _.isEmpty(teamData)) ?
                  <>
                    <CardTitle text="Select your Team" classes="mb-2" />
                    <Link to={`/${WEB_ROUTES.PROFILE}`} replace={true}>Create team</Link>
                  </> :
                  <TeamList data={teamData} selectedTeam={selectedTeam} setSelectedTeam={setSelectedTeam} />
              }
            </Col>

            <Col md={6}>
              <CheckOutSummary
                key={selectedTeam}
                data={data}
                isDisabled={_.isUndefined(selectedTeam)}
                isSubmitted={isSubmitted}
                setIsSubmitted={setIsSubmitted}
                handleCheckOutSubmit={handleCheckOut}
                handleCheckOutSubmit2={handleCheckOut2}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}