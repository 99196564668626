import React, { useState } from 'react'
import Tabs from '../../Components/Tabs/Tabs'
import { Col, Row } from 'react-bootstrap';
import TeamsTabContent from './TeamsTabContent';
import { TournamentAPI } from '../../Api/Tournament';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { Toast } from '../../Hooks/useToast';
import _ from 'lodash';
import { Description, EmptyContainer, Loader } from '../../Components/CustomComponents';

const TeamsContent = ({ groups = [] }) => {
  const { route: base_route } = useSelector(state => state.default);
  const tournamentAPI = TournamentAPI();
  const [key, setKey] = useState(groups.length ? groups[0].value : '');

  const QUERY_KEY = base_route + 'tournament/age-group/' + key + '/team'
  const inlineItemStyles = {
    background: '#222232',
    margin: '5px',
    padding: '10px 20px',
  };



  const { data, isError, isLoading } = useQuery({
    queryKey: QUERY_KEY,
    enabled: !_.isEmpty(key),
    queryFn: async () => await tournamentAPI.getTeamsByID(key),
    select: (res) => res.data,
    onError: (err) => Toast(err.message, 'error', false),
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })


  return (
    <div className='p-2'>
      {/* <SubTitle text='Teams Age Group' /> */}
      <p className="text-white m-0 fw-bold fs-5">
        Team Age Group
      </p>
      <Row>
        <Col className='p-2'>
        <Tabs tabs={groups} current={key} onChange={setKey} itemStyle={inlineItemStyles} containerStyles={{ width: 'fit-content' }} />
        </Col>
      </Row>

      {isLoading ? <Loader /> :
        (isError || _.isEmpty(data)) ? <EmptyContainer component={<Description text={"No team registered in this age group"} classes='fs-3' />} /> :
          <TeamsTabContent data={data} />
      }
    </div>
  )
}

export default TeamsContent
