import { Description, SubTitle } from '../../Components/CustomComponents';
import { Card, Col, Row } from 'react-bootstrap';
import { TournamentAPI } from '../../Api/Tournament';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import _ from 'lodash';
import styles from "../../Components/Layouts/styles/PrimeTimeLayout.module.scss";
import TreeComponent from './Components/TreeComponent';


const BracketContent = ({ ageGroups = [] }) => {
    const { route: base_route } = useSelector(state => state.default);
    const tournamentAPI = TournamentAPI();
    const defaultAgeGroup = ageGroups?.length > 0 ? ageGroups[0]?.ageSlug : '';
    const [key, setKey] = useState(defaultAgeGroup);
    const [teams, setTeams] = useState([])
    const [brackets, setBrackets] = useState({})

    const GetTeamsByAgeGroup = async () => {
        try {
            if (!key) {
                console.error("No group slug provided.");
                return;
            }

            const res = await tournamentAPI.getRegisteredTeamsByID(key);
            if (res) {
                setTeams(res);
            } else {
                console.error("Error fetching teams:", res.error || "Unknown error");
            } 
        } catch (error) {
            console.error('Error fetching teams:', error);
        }
    };

    const GetFixtureBySlug = async () => {
        try {
            if (!key) {
                console.error("No group slug provided.");
                return;
            }

            const res = await tournamentAPI.getFixtureByID(key);
            if (res?.data) {
                setBrackets(res?.data);
            } else{
                console.error("Error fetching fixture:", res.error || "Unknown error");
            }
        } catch (error) {
            console.error('Error fetching fixture:', error);
        }
    };

    useEffect(() => {
        if (!_.isEmpty(key)) {
            GetFixtureBySlug(key);
            GetTeamsByAgeGroup(key);
        }
    }, [key]);

    // const QUERY_KEY = base_route + 'tournament/age-group/' + key + '/team'
    // const inlineItemStyles = {
    //     background: '#222232',
    //     margin: '5px',
    //     padding: '10px 20px',
    // };

    return (
        <div className='p-2'>
            <SubTitle
                text='Tournament Brackets'
                classes=' mt-2'
            />

            {/* Age Groups */}
            <div className="container text-center">
                <h3>Age Groups</h3>
                <div className="btn-toolbar d-flex justify-content-center" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group" role="group" aria-label="Basic example">
                        {ageGroups?.map((group, index) => (
                            <button key={index} className={`${styles.tab} btn ${(key === group.ageSlug) ? styles.active : ''}`} onClick={() => setKey(group.ageSlug)} >
                                {group.label}
                            </button> 
                        ))}
                    </div>
                </div>
            </div>

            <Row>
                <Col>
                    <TreeComponent brackets={brackets} ageGroupId={key} teams={teams} />
                </Col>
            </Row>
            
       </div>
    );
    }
export default BracketContent;