import React, { useState } from 'react'
import { Col, } from 'react-bootstrap'
import { FaCircleCheck } from "react-icons/fa6";
import cssStyles from '../index.module.css';

const TeamSelectionCard = ({ onSelect, data, isSelected }) => {
    return (
        <div
            className='p-3 rounded-4'
            style={{ background: '#48556F', display: 'flex', flexDirection: 'row', alignItems: 'center', }}
            onClick={onSelect} role='button' >
            <Col
                md={10}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}
            >
                <img
                    className={cssStyles.teamAvatar}
                    src={data.image_url}
                    alt="Team 2 Logo"
                />
                <div style={{ display: 'flex', flexDirection: 'column', paddingRight: '10px' }} >
                    <p className=' fw-normal fs-5 lc-1 m-0 text-white'>{data.name}</p>
                    <p className='  fs-9 lc-1 m-0' style={{ color: '#8B8B8B' }}>{`Date of birth: ${data.dateOfBirth}`}</p>
                    <p className='  fs-9 lc-1 m-0' style={{ color: '#8B8B8B' }}>{`Age ${data.age} Years`}</p>
                </div>
            </Col>
            <Col
                md={2}
                style={{ display: 'flex', }}
            >
                <FaCircleCheck color='white'
                    style={{ fontSize: '35px', color: isSelected ? '#FBBE21' : '#26262E', marginLeft: 'auto', }}
                />
            </Col>
        </div>
    )
}

export default TeamSelectionCard
