import React, { useState } from "react";

import styles from "../index.module.scss"

import { CardTitle, Description, EmptyContainer, Loader, SubTitle } from "../../../Components/CustomComponents";
import { HiPlus } from "react-icons/hi";
import { FaBan, FaCheck } from "react-icons/fa";
import { Badge, Spinner } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { PlayerAPI } from "../../../Api/Player";
import { Toast } from "../../../Hooks/useToast";
import _ from "lodash";


export default function Kid({ isAddKid = false, isBanned = false, handleAddKid = () => { } }) {
    const QUERY_KEY = 'user/player'
    const playerAPI = PlayerAPI();
    const queryClient = useQueryClient();
    const [isDeleted, setIsDeleted] = useState(undefined);
    const { data, isError, isLoading } = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: async () => await playerAPI.get(),
        select: (data) => data.data,
        onError: (err) => Toast(err.message, 'error', false),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
    })

    const deleteMutation = useMutation(playerAPI.delete, {
        onSuccess: (data, args) => {
            const prev_data = queryClient.getQueryData([QUERY_KEY]);
            const new_data = {
                ...prev_data,
                data: !_.isEmpty(prev_data?.data) ? prev_data.data.filter(item => item.slug !== args) : []
            }
            queryClient.setQueryData([QUERY_KEY], new_data);
            setIsDeleted(undefined);
        },
        onError: (err, args) => {
            if (err.code === 409) {
                const prev_data = queryClient.getQueryData([QUERY_KEY]);
                const new_data = {
                    ...prev_data,
                    data: _.isEmpty(prev_data?.data) ? [] :
                        prev_data.data.map(item => {
                            if (item.slug !== args) return item
                            return {
                                ...item,
                                is_registered: true
                            }
                        })
                }
                queryClient.setQueryData([QUERY_KEY], new_data);
            }
            else {
                Toast(err.message, 'error', false)
            }
            setIsDeleted(undefined)
        }
    });

    const handleRemovePlayer = (index) => {
        if (isDeleted) return;

        setIsDeleted(index);
        const player_slug = data[index].slug;
        deleteMutation.mutate(player_slug)

    }

    return (
        <div>
            <div className='mt-4 mb-1 d-flex flex-row justify-content-between align-items-baseline'>
                <SubTitle
                    text={'My Kids'}
                    classes='mb-1'
                />

                {!isAddKid || isBanned ? "" :
                    <button
                        className={`${styles.messageButton} bg`}
                        onClick={handleAddKid}
                    >
                        <span className={styles.icon}><HiPlus /></span>
                        Add a Kid
                    </button>
                }
            </div>
            <div className={styles.scrollView} >
            {isLoading ? <Loader /> :
                (isError || _.isEmpty(data)) ? <EmptyContainer component={<SubTitle text="No kids found" classes="fs-5 fw-lighter" />} /> :
                    (data ?? []).map((item, index) => {
                        return (
                            <div
                                className={`${styles.ticketContainer} mt-3 mb-2`}
                                key={index}
                            >
                                <div className={styles.teamContainer}>

                                    <div className={styles.tournament}>
                                        <img
                                            src={item.image_url ?? '/images/slide-diamond.png'}
                                            // src={'/images/slide-diamond.png'}
                                            alt='User'
                                            className={styles.tournamentLogo}
                                        />
                                        <div className={styles.nameContainer}>
                                            <CardTitle
                                                text={item.name}
                                                classes='lc-1'
                                            />
                                            <div className={styles.location}>
                                                <Description
                                                    text={`Age: ${item.age} years`}
                                                    classes='fs-9 lc-1 text-white'
                                                />
                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <div className={`${styles.qrContainer} d-flex flex-row`}>
                                    {item.is_banned ? <FaBan style={{ color: "#E95B5B", fontSize: "2rem", marginRight: "10px" }} /> : ""}
                                    {item.is_registered ? <FaCheck style={{ color: "green", fontSize: "2rem", marginRight: "5px" }} /> :
                                        (_.isUndefined(isDeleted) || (isDeleted !== index)) ?
                                            <Badge
                                                className="p-2 fs-8"
                                                bg=""
                                                pill
                                                style={{ cursor: "pointer", padding: "0 8px", fontWeight: 'normal', background: '#E95B5B', marginLeft: 'auto' }}
                                                onClick={() => handleRemovePlayer(index)}>
                                                Remove
                                            </Badge>
                                            :
                                            <Badge
                                                className=" fs-8"
                                                bg=""
                                                pill
                                                style={{
                                                    padding: "0 8px",
                                                    fontWeight: 'normal',
                                                    background: '#E95B5B',
                                                    marginLeft: 'auto',
                                                    minWidth: "60px",
                                                    minHeight: "30px",
                                                    textAlign: "center",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}
                                            >
                                                <Spinner style={{ fontSize: "6px" }} size="sm" />
                                            </Badge>
                                    }
                                </div>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}