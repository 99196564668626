import React from "react";
import styles from "./index.module.scss";

import { Form, Spinner } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";

export const ShadowContainer = () => {
  return <div className={styles.ShadowContainer}></div>;
};

export const BackButton = ({
  isRelative = false,
  handleBackButton = () => { },
}) => {
  return (
    <div
      className={`${styles.BackButton} ${isRelative ? styles.relative : ""}`}
      onClick={handleBackButton}
    >
      <IoIosArrowBack />
    </div>
  );
};

export const Select = React.forwardRef(
  (
    {
      options = [],
      classes = "mt-1 mb-2",
      style = {},
      size = "lg",
      placeholder = "",
      name = "",
      value = "",
      onChange = () => { },
      disabled = false,
    },
    ref
  ) => {
    return (
      <Form.Control 
        as="select"
        name={name}
        ref={ref}
        size={size}
        className={`${styles.Input} ${classes}`}
        placeholder={placeholder}
        onChange={(e) => onChange(name, e.target.value)}
        disabled={disabled}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.value} selected={value && option.value === value ? 'selected' : undefined} >
            {option.label}
          </option>
        ))}
      </Form.Control>
    );  

  }
);

export const Input = React.forwardRef(
  (
    {
      type = "text",
      classes = "mt-1 mb-2",
      style = {},
      size = "lg",
      placeholder = "",
      name = "",
      value = "",
      onChange = () => { },
      disabled = false,
    },
    ref
  ) => {
    return (
      <Form.Control
        name={name}
        ref={ref}
        type={type}
        size={size}
        className={`${styles.Input} ${classes}`}
        placeholder={placeholder}
        onChange={(e) => onChange(name, e.target.value)}
        disabled={disabled}
      />
    );
  }
);

export const InputCustom = (
  {
    type = "text",
    classes = "mt-1 mb-2",
    style = {},
    size = "lg",
    placeholder = "",
    name = "",
    value = "",
    onChange = () => { },
    disabled = false,
  },
) => {
  return (
    <Form.Control
      name={name}
      type={type}
      size={size}
      className={`${styles.Input} ${classes}`}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(name, e.target.value)}
      disabled={disabled}
    />
  );
}

export const Description = ({
  text = "",
  classes = "",
  onClick = () => { },
}) => {
  return (
    <p className={`${styles.Description} ${classes}`} onClick={onClick}>
      {text}
    </p>
  );
};

export const CardDescription = ({ text = "", classes = "" }) => {
  return <p className={`${styles.Description} ${classes} fs-10`}>{text}</p>;
};

export const Title = ({ text = "", classes = "" }) => {
  return <h1 className={`${styles.Header} ${classes}`}>{text}</h1>;
};

export const SubTitle = ({ text = "", classes = "" }) => {
  return <h2 className={`${styles.SubTitle} ${classes}`}>{text}</h2>;
};
export const CardTitle = ({ text = "", classes = "" }) => {
  return <h6 className={`${styles.CardTitle} ${classes} `}>{text}</h6>;
};
export const CardMatchTitle = ({ text = "", classes = "" }) => {
  return (
    <h6 className={`${styles.CardMatchTitle} ${classes} mt-1 mb-1`}>{text}</h6>
  );
};

export const Button = ({
  text = "",
  classes = "",
  style = {},
  onClick = () => { },
  disabled = false,
  isSubmitted = false,
  type = "",
}) => {
  return (
    <button
      className={`${styles.Button} ${classes} ${isSubmitted || disabled ? styles.disabled : ""
        }`}
      style={style}
      type={type}
      onClick={!disabled ? onClick : () => { }}
    >
      {text}
      {isSubmitted ? <Spinner size="sm" className="ms-3" /> : ""}
    </button>
  );
};

export const TextDivider = ({ text = "", classes = "" }) => {
  return <div className={`${styles.TextDivider} ${classes}`}>{text}</div>;
};

export const UnorderedList = ({
  lists = [],
  classes = "",
  item_classes = "",
}) => {
  return (
    <ul className={`${styles.UL} ${classes} fs-7`}>
      {lists.map((item, index) => (
        <li key={index} className={`${item_classes} mt-2 mb-2 fs-7`}>
          {item}
        </li>
      ))}
    </ul>
  );
};

export const Checkbox = ({
  label = "",
  classes = "",
  checked = false,
  onChange = () => { },
}) => {
  return (
    <Form.Check
      type="checkbox"
      className={`${styles.Checkbox} ${classes}`}
      label={label}
      checked={checked}
      onChange={onChange}
    />
  );
};

export const InputOTP = ({ props }) => {
  return (
    <Form.Control
      {...props}
      type="text"
      className={`${styles.Input} ${styles.InputOTP}`}
      size="lg"
    />
  );
};

export const LiveBadge = ({ text = "Live", classes = "" }) => {
  return <span className={`${styles.LiveBadge} ${classes}`}>{text}</span>;
};

export const Score = ({ text = "", classes = "" }) => {
  return <span className={`${styles.Score} ${classes}`}>{text}</span>;
};

export const Loader = ({ variant = "dark", containerStyle = '' }) => {
  return (
    <div className={`${styles.Loader} ${containerStyle}`}>
      <Spinner variant={variant} />
    </div>
  );
};

export const EmptyContainer = ({
  text = "No record found",
  component = <SubTitle text={text} />,
  classes = "",
}) => {
  return (
    <div className={`${styles.EmptyContainer} ${classes}`}>{component}</div>
  );
};


export const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;
