import React from "react";
import cssStyles from "../index.module.css";
import { Description, EmptyContainer, Loader } from "../../../Components/CustomComponents";
import { FaCircleXmark } from "react-icons/fa6";
import { useQuery, useQueryClient } from "react-query";
import { TeamAPI } from "../../../Api/Team";
import { Toast } from "../../../Hooks/useToast";
import _ from "lodash";

export default function TeamDetails({ slug = '', hideViewTeamModal }) {
    const teamAPI = TeamAPI()

    const QUERY_KEY = 'team/' + slug;
    const { data, isError, isLoading } = useQuery({
        queryKey: QUERY_KEY,
        queryFn: async () => await teamAPI.getTeam(slug),
        select: (data) => data.data,
        onError: (err) => Toast(err.message, 'error', false),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
    })

    return (
        <div
            className={cssStyles.modalContainer}
            style={{ height: "90%", maxHeight: "500px" }}
        >
            <div className="d-flex align-items-center w-100 justify-content-between mb-4">
                <p className="fs-4 fw-medium m-0">View Team</p>
                <FaCircleXmark
                    onClick={hideViewTeamModal}
                    style={{ cursor: "pointer" }}
                />
            </div>
            {isLoading ? <Loader /> :
                (isError || _.isEmpty(data)) ? <EmptyContainer text="No team found" /> :
                    <div
                        className={cssStyles.contentContainer}
                        style={{ backgroundColor: "#181829" }}
                    >
                        <div className={cssStyles.teamInfoContainer}>
                            <div className={`${cssStyles.userContainer} my-3`}>
                                <img
                                    src={data.coach_image_url}
                                    alt="Coach Profile"
                                    className={cssStyles.image}
                                />
                                <Description
                                    text={data.coach_name}
                                    classes="ps-2 lc-1 fs-8"
                                />
                                <span className={cssStyles.coachBadge}>Coach</span>
                            </div>
                            {data.team_members.map((member, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`${cssStyles.userContainer} my-3`}
                                    >
                                        <img
                                            src={member.image_url}
                                            alt="User Profile"
                                            className={cssStyles.image}
                                        />
                                        <Description
                                            text={member.name}
                                            classes="ps-2 lc-1 fs-8"
                                        />

                                        {/* <Badge className="p-2 fs-7" bg="" pill style={{ fontWeight: 'normal', background: '#E95B5B', marginLeft: 'auto' }}>
                                                    Remove
                                                </Badge> */}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
            }
        </div>
    )
}