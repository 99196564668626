import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROUTES, BASE_URL } from "../constants";

export const AuthApi = createApi({
  reducerPath: "loginuser",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    logInUser: builder.mutation({
      query: (body) => {
        return {
          url: API_ROUTES.LOGIN,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: "48aaf1a4-9c95-478b-b93f-739ddfa1b38e",
          },
          body: {
            ...body,
            device_type: "web",
            device_token: "1234567890",
          },
        };
      },
      transformErrorResponse: (data) => data.data,
    }),
    createUser: builder.mutation({
      query: (body) => {
        return {
          url: API_ROUTES.CREATE_USER,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: "48aaf1a4-9c95-478b-b93f-739ddfa1b38e",
          },
          body: {
            ...body,
            device_type: "web",
            device_token: "1234567890",
          },
        };
      },
      transformErrorResponse: (data) => data.data,
    }),
    verifyRegisterOTP: builder.mutation({
      query: (body) => {
        return {
          url: API_ROUTES.VERIFY_OTP_REGISTER,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: "48aaf1a4-9c95-478b-b93f-739ddfa1b38e",
          },
          body: {
            ...body,
            device_type: "web",
            device_token: "1234567890",
          },
        };
      },
      transformErrorResponse: (data) => data.data,
    }),
    getProfile: builder.mutation({
      query: (api_token) => {
        return {
          url: API_ROUTES.GET_PROFILE,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": api_token,
          }
        };
      },
      transformErrorResponse: (data) => data.data,
    }),
    forgotPassword: builder.mutation({
      query: (body) => {
        return {
          url: API_ROUTES.FORGOT_PASSWORD,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: "48aaf1a4-9c95-478b-b93f-739ddfa1b38e",
          },
          body: {
            ...body,
            device_type: "web",
            device_token: "1234567890",
          },
        };
      },
      transformErrorResponse: (data) => data.data,
    }),
  }),
});

export const {
  useLogInUserMutation,
  useCreateUserMutation,
  useVerifyRegisterOTPMutation,
  useGetProfileMutation,
  useForgotPasswordMutation,
} = AuthApi;
