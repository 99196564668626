import React, { useMemo, useEffect, useState } from "react";
import { TournamentAPI } from "../../Api/Tournament";
import { useQuery } from "react-query";
import { Toast } from "../../Hooks/useToast.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "../../Components/Layouts/styles/PrimeTimeLayout.module.scss";
import TournamentTeams from "./TournamentTeams.js";
import BracketContent from "./BracketContent.js";
import DetailsContent from "./DetailsContent.js";
import cssStyles from "./index.module.css";
import Tabs from "../../Components/Tabs/Tabs";
import ModalContainer from "../../Components/ModalContainer/index.js";
import RegisterTeam from "./Components/RegisterTeam.js";
import RulesContent from "./RulesContent.js";
import Default from '../../assets/images/default.jpg';
import { useSelector } from "react-redux";
import { Card } from 'react-bootstrap';

export default function TournamentView({ setPage }) {
    const tournamentAPI = TournamentAPI();
    const [ageGroups, setAgeGroups] = useState([]);
    const navigate = useNavigate();
    const { slug } = useParams();
    const [teams, setTeams] = useState([]);
    const [key, setKey] = useState("details");
    const [isShowRegisterModal, setIsShowRegisterModal] = useState(false);
    const { isCoach, isViewer, route, user } = useSelector((state) => state.default);

    // Get the tournament
    const { data: tournament = {}, isError, isLoading } = useQuery({
        queryKey: ['tournament', slug],
        queryFn: async () => await tournamentAPI.getByID(slug),
        select: (tournamentData) => {
            // Process the age groups
            const processedGroups = (tournamentData.data.age_groups?.map(age => ({
                label: age?.label,
                ageSlug: age?.value,
                id: age?.id
            })) || []).sort((a, b) => a.id - b.id);
            return { ...tournamentData.data, ageGroups: processedGroups };
        },
        onError: (err) => Toast(err.message, 'error', false),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
    });

    // Fetch teams based on the age group slug
    const fetchTeams = async (groupSlug) => {
        try {
            if (!groupSlug) {
                console.error("No group slug provided.");
                return;
            }

            const response = await tournamentAPI.getRegisteredTeamsByID(groupSlug);

            if (!response || response.error) {
                console.error("Error fetching teams:", response.error || "Unknown error");
                return;
            }

            const responseTeams = response.map(team => team.Registration_TeamSlug);
            setTeams(responseTeams);
        } catch (error) {
            console.error("Error in fetchTeams function:", error);
        }
    };

    const array = useMemo(
        () => [
          {
            value: "details",
            label: "Details",
          },
        //   {
        //     value: "brackets",
        //     label: "Brackets",
        //   },
          {
            value: "teams",
            label: "Teams",
          },
          {
            value: "rules",
            label: "Rules",
          },
        ],
        []
    );
    useEffect(() => {
        // Update the age groups state when tournament data changes
        if (tournament && tournament.ageGroups) {
            setAgeGroups(tournament.ageGroups);
        }
    }, [tournament]);

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    };

    const hideGetRegisterModal = () => {
        setIsShowRegisterModal(false);
    };
    const handleRegister = () => {
        setIsShowRegisterModal(true);
    };

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error loading team data</div>;

    

    const tabContent = () => {
        switch (key) {
          case "details":
            return <DetailsContent data={tournament} />;
          case "brackets":
            return <BracketContent ageGroups={ageGroups} />;
          case "teams":
            return <TournamentTeams ageGroups={ageGroups} />;
          case "rules":
            return <RulesContent text={tournament?.rules} />;
    
          default:
            return <DetailsContent />;
        }
    };
    return (
        <div className={styles.sectionContainer}>
            {isShowRegisterModal ? (
                <ModalContainer handleClose={hideGetRegisterModal}>
                <RegisterTeam
                    data={tournament}
                    handleClose={hideGetRegisterModal}
                    TOURNAMENT_DETAIL_QUERY_KEY={"/tournament/" + slug}
                />
                </ModalContainer>
            ) : (
                ""
            )}
            {isCoach && !user?.is_banned ? (<div className="row mb-2">
                <div className="col-12 d-flex justify-content-end">
                    <button className="btn btn-primary"
                        onClick={() => {
                            handleRegister(tournament.slug);
                        }}
                    >
                        Register
                    </button>
                </div>
            </div>
            ) : (
                ""
            )}

            {/* Tournament View */}
            <div className="row mb-2">
                <div className="col-6 text-start">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <h3>{tournament.name}</h3>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <img src={tournament.media_url || Default} alt={tournament.name} style={{ maxWidth: '100%', height: '250px', objectFit: 'contain', borderRadius: '4px' }}/>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className={cssStyles.beforeTabs}>
                        <Tabs tabs={array} current={key} onChange={setKey} />
                    </div>
                </div>
            </div>
            <div style={{ color: "black" }}>{tabContent()}</div>
        </div>
    );
}
