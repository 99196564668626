import React, { useMemo } from "react";
import cssStyles from "./TeamList.module.css";

import { CardTitle, Description } from "../../../Components/CustomComponents";
import TeamDropDownMenu from "./TeamDropDownMenu";

export default function TeamList({
  data = [],
  selectedTeam,
  setSelectedTeam = () => { },
}) {
  const team = useMemo(() => {
    return data[selectedTeam];
  }, [selectedTeam]);


  return (
    <div>
      <CardTitle text="Select your Team" classes="mb-2" />
      <TeamDropDownMenu
        data={data}
        selectedTeam={selectedTeam}
        setSelectedItem={setSelectedTeam}
      />
      <div className={cssStyles.teamInfoContainer}>
        <div className="d-flex justify-content-between align-items-center">
            <CardTitle text="Team" classes="text-dark"/>
            <span className={cssStyles.coachBadge}>Coach</span>
        </div>
        <div className={`${cssStyles.userContainer} my-3 d-flex justify-content-between`}>
          <img
            src={team?.coach_image_url}
            alt="Coach Profile"
            className={cssStyles.image}
          />
          <Description
            text={team?.coach_name}
            classes="ps-2 lc-1"
          />
        </div>
        <hr style={{borderBottom: '1px solid rgb(0, 0, 0)'}}/>
        {(team?.team_members ?? []).map((player, index) => {
          return (
            <>
            <div key={index} className={`${cssStyles.userContainer} my-3 d-flex justify-content-between`}>
              <img
                src={player.image_url}
                alt="User Profile"
                className={cssStyles.image}
              />
              <Description text={player.name} styles classes="ps-2 lc-1" />
            </div>
            <hr style={{borderBottom: '1px solid rgb(0, 0, 0)'}}/>
            </>
          );
        })}
      </div>
    </div>
  );
}
