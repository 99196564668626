import React, { useState } from "react";
import styles from "../../Components/Layouts/styles/AccountLayout.module.scss"
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { Button, Description, Input, Title } from "../../Components/CustomComponents";
import { ACCOUNT_ENUM } from "./Route";
import { useLogInUserMutation } from "../../services/UserService";
import { Toast } from "../../Hooks/useToast";
import { setUser } from "../../Features/DefaultReducer";
import { useDispatch } from "react-redux";
import { PaymentAPI } from "../../Api/Payment";
import { useNavigate } from 'react-router-dom';
import { baseRoute } from "../../Helper";


export default function Login({ setPage }) {
    // const stripe = new Stripe(`${process.env.STRIPE_SECRET_KEY}`)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [data, setData] = useState({
        email: "",
        password: ""
    })
    const [postUser] = useLogInUserMutation();

    const handleForgotPasswordClick = () => {
        setPage(ACCOUNT_ENUM.FORGOT_PASSWORD)
    }

    const handleSignUpClick = () => {
        setPage(ACCOUNT_ENUM.REGISTER)
    }

    const handleChange = (name, value) => {
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    const Paymentapi = PaymentAPI()
    const handleSubmit = async () => {
        if (isSubmitted) return;
        if (!data.email) return Toast('Email is required', 'error', false)
        if (!data.password) return Toast('Password is required', 'error', false)
        try {
            setIsSubmitted(true);
            Toast('Validating credentials', 'loading', true)
            const response = await postUser(data).unwrap();
            dispatch(setUser(response))
            Toast('User logged in successfully', 'success', false)
            navigate(baseRoute(ACCOUNT_ENUM.DASHBOARD));
        }
        catch (err) {
            if (err.code === 428) {
                setPage(ACCOUNT_ENUM.OTP, { email: data.email, previousRoute: ACCOUNT_ENUM.LOGIN })
                Toast('Verify your email to continue login', 'error', false)
            }
            else {
                Toast(err.message, 'error', false)
            }
            setIsSubmitted(false)
        }
    }

    return (
        <div>
            <AccountLayout>
                <Description text="Hello there," />
                <Title text="Welcome back" classes="mb-2" />
                <Input
                    placeholder="Email Address"
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                />
                <Input
                    classes="mt-2 mb-2"
                    placeholder="Password"
                    type="password"
                    name="password"
                    value={data.password}
                    onChange={handleChange}
                />
                <Button
                    text='Login'
                    classes="mt-3 mb-2"
                    style={{ border: "2px solid #848A93" }}
                    onClick={handleSubmit}
                    isSubmitted={isSubmitted}
                />
                {/* <TextDivider text='Or continue with' classes="mt-4 mb-3" />
                <Description
                    text="Continue with Google, Apple or Facebook, you agree to Prime Time Tournaments Terms and Conditions & Privacy Policy"
                    classes='m-2 fs-8 text-center'
                /> */}
                
                <a onClick={handleForgotPasswordClick} className={`ms-1 fs-7 cursor-pointer ${styles.linkHover}`}>Forgot Password?</a>
                <div
                    className='d-flex justify-content-center flex-direction-row mt-2'
                >
                    <Description
                        text='Not a memeber?'
                        classes="fs-7"
                    />
                    <a onClick={handleSignUpClick} className={`ms-1 fs-7 cursor-pointer ${styles.linkHover}`}>Sign Up</a>
                    

                </div>

            </AccountLayout>
        </div>
    )
}