import React from "react";
import { Col, Row } from "react-bootstrap";
import BorderedCard from "./BorderedCard";
import { useQueryClient } from "react-query";
import { transformData } from "../../Helper";
import moment from "moment";

const RegistrationCard = ({ QUERY_KEY }) => {
  const queryClient = useQueryClient();
  const data = transformData(queryClient.getQueryData(QUERY_KEY))
  const heading = {
    fontSize: 24,
    color: '#0AA7FF',
    marginBottom: '0px',
  };
  const para = {
    fontSize: 16,
  }
  return (
    <BorderedCard classes="h-100">
      <div
        style={{
          backgroundColor: "#48556F",
          borderRadius: "22px",
          width: "100%",
          height: "100%",
        }}
      >
        <Row className="h-100">
          <Col
            className="text-center text-white justify-content-center d-flex flex-column align-items-center"
            lg={6}
            md={3}
            xs={6}
            style={{
              borderBottom: "1px solid #2D2D2D",
              borderRight: "1px solid #2d2d2d",
            }}
          >
            <h4 style={heading}>{`$${data?.registration_fee}`}</h4>

            <p style={para}>Registration Fee</p>
          </Col>
          <Col
            className="text-center text-white justify-content-center d-flex flex-column align-items-center"
            lg={6}
            md={3}
            xs={6}
            style={{
              borderBottom: "1px solid #2D2D2D",
            }}
          >
            <h4 style={heading}>{data?.sports_type}</h4>
            <p style={para}>Sport</p>
          </Col>
          <Col
            className="text-center text-white justify-content-center d-flex flex-column align-items-center"
            lg={6}
            md={3}
            xs={6}
            style={{ borderRight: "1px solid #2D2D2D" }}
          >
            <h4 style={heading}>{moment(data?.registration_start_date).format('DD MMM, YYYY')}</h4>
            <p style={{ ...para, fontSize: ".8rem" }}>Registration Start Date</p>
          </Col>

          <Col
            className="text-center text-white justify-content-center d-flex flex-column align-items-center"
            lg={6}
            md={3}
            xs={6}
          >
            <h4 style={heading}>{moment(data?.registration_end_date).format('DD MMM, YYYY')}</h4>
            <p style={{ ...para, fontSize: ".8rem" }}>Registration End Date</p>
          </Col>

        </Row>
      </div>
    </BorderedCard >
  );
};

export default RegistrationCard;
