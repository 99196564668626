import React, { useState } from 'react';

import styles from './styles/ChangePassword.module.scss'
import { Button, Description, Input, SubTitle, UnorderedList } from '../../Components/CustomComponents';
import { FIELDS_MAPPING, PASSWORD_PATTERN } from '../../constants';
import { ProfileAPI } from '../../Api/Profile';
import { logout } from '../../Features/DefaultReducer';
import { useDispatch } from 'react-redux';
import { Toast } from '../../Hooks/useToast';
import { Spinner } from 'react-bootstrap';

export default function ChangePassword() {
    const dispatch = useDispatch()
    const profileAPI = ProfileAPI()
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [data, setData] = useState({
        current_password: "",
        new_password: "",
        confirm_password: ""
    })

    const INITIAL_ERROR_PAYLOAD = {
        new_password: "",
        confirm_password: ""
    }
    const [error, setError] = useState(INITIAL_ERROR_PAYLOAD)

    const validateFields = (name, value) => {
        if (!value) return `${FIELDS_MAPPING[name]} should not be empty`

        switch (name) {
            case "new_password":
                if (!(PASSWORD_PATTERN.test(value))) return `${FIELDS_MAPPING[name]} format is invalid`
                return "";
            case "confirm_password":
                if (data.new_password !== value)
                    return 'Confirm password should be same as new password';
                return "";
        }

        return ""
    }

    const handleInputChange = (name, value) => {
        if (isSubmitted) return;
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })

    }

    const handleSubmit = async () => {
        if (isSubmitted) return;

        const error_logs = {
            "current_password": validateFields("current_password", data.current_password),
            "new_password": validateFields("new_password", data.new_password),
            "confirm_password": validateFields("confirm_password", data.confirm_password)
        }
        if (!Object.values(error_logs).every(item => !item)) {
            setError(prev => {
                return {
                    ...prev,
                    ...error_logs
                }
            })
            return;
        } else {
            setError(INITIAL_ERROR_PAYLOAD)
        }

        try {
            setIsSubmitted(true);

            await profileAPI.changePassword(data);
            setData({
                current_password: "",
                new_password: "",
                confirm_password: ""
            })
            // dispatch(logout())
            Toast('Password has been updated successfully', 'success', false)
        }
        catch (err) {
            Toast(err.message, 'error', false)
        }
        finally {
            setIsSubmitted(false)
        }
    }


    return (
        <div className={`${styles.ChangePassword} container`}>
            <SubTitle
                text='Change Password'
                classes='text-white'
            />
            <div className={`row`}>
                
                <div className='col-sm-12 col-md-6'>
                    <Description
                        text='Current Password'
                        classes='mb-1 text-white'
                    />
                    <Input
                        type="password"
                        placeholder="Current Password"
                        name="current_password"
                        value={data.current_password}
                        onChange={handleInputChange}
                    />
                    {error.current_password ? <p className='w-100 text-danger fs-7 fw-lighter text-left ps-1 mb-1'>{error.current_password}</p> : ""}
                </div>
                <div className='col-sm-12 col-md-6'>
                    <Description
                        text='Set New Password'
                        classes='mt-1 mb-1 text-white'
                    />
                    <Input
                        type="password"
                        placeholder="New Password"
                        name="new_password"
                        value={data.new_password}
                        onChange={handleInputChange}
                    />
                    {error.new_password ? <p className='w-100 text-danger fs-7 fw-lighter text-left ps-1 mb-3'>{error.new_password}</p> : ""}
                    <Input
                        type="password"
                        placeholder="Confirm Password"
                        name="confirm_password"
                        value={data.confirm_password}
                        onChange={handleInputChange}
                    />
                    {error.confirm_password ? <p className='w-100 text-danger fs-7 fw-lighter text-left ps-1 mb-3'>{error.confirm_password}</p> : ""}
                </div>
                
                <UnorderedList
                    lists={[
                        'At least 12 characters long but 14 or more is better.',
                        'A combination of uppercase letters, lowercase letters, numbers, and symbols.'
                    ]}
                    classes='fs-6 mt-4 mb-4'
                />

                <div className={`${styles.saveButton} col-6 offset-3`}>
                    <Button
                        text={<div className='d-flex flex-row align-items-center justify-content-center'>Change Password {isSubmitted ? <Spinner size='sm' color='white' className='ms-3' /> : ""}</div>}

                        classes='fs-6 mb-2 mt-auto'
                        style={{ border: '1px solid white' }}
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </div >
    )
}