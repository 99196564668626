import React from 'react';
import ModalContainer from '../ModalContainer';
import { FaCircleXmark } from 'react-icons/fa6';
import { FaBell } from 'react-icons/fa';
import cssStyles from './styles/notification.module.css';

const NotificationModal = ({ handleNotificationModalClose }) => {
    return (
        <div>
            <ModalContainer
                handleClose={handleNotificationModalClose}
            >
                <div className={cssStyles.modalContainer}>
                    <div className='d-flex align-items-center w-100 justify-content-between mb-4'>
                        <p className='fs-4 fw-medium m-0'>
                            Notification
                        </p>
                        <FaCircleXmark onClick={handleNotificationModalClose} style={{ cursor: 'pointer' }} />
                    </div>
                    {Notification()}
                    <hr></hr>
                    {Notification()}
                    <hr></hr>
                    {Notification()}
                    <hr></hr>
                    {Notification()}
                    <hr></hr>
                    {Notification()}
                </div>
            </ModalContainer>
        </div>
    )

    function Notification() {
        return <div className='d-flex align-items-center w-100 justify-content-start'>

            <div className=' rounded-5 d-flex justify-content-center align-items-center' style={{ backgroundColor: '#222232', width: '50px', height: '50px' }}>
                <FaBell />
            </div>

            <div className='ms-3'>
                <p className='fs-6 fw-semibold m-0'>Lorem Ipsum</p>
                <p className='m-0 fs-8'>Lorem ipsum dolor sit amet</p>
            </div>

        </div>;
    }
}

export default NotificationModal
