import React, { useMemo } from "react";
import { Route, Routes, Navigate } from 'react-router-dom';

import { WEB_ROUTES } from "./constants";
import { baseRoute } from "./Helper";
import PublicRoute from "./Components/Routes/PublicRoute";

import Account from "./Pages/Account";
import ProtectedLayout from "./Components/Layouts/ProtectedLayout";
import Dashboard from "./Pages/Dashboard";
import Tournaments from "./Pages/Tournaments";
import Teams from "./Pages/Teams";
// import Messages from "./Pages/Messages";
import Setting from "./Pages/Setting";
import Profile from "./Pages/Profile";
import PrimeTime from "./Pages/PrimeTime";
import ProtectedRoute from "./Components/Routes/ProtectedRoute";
import { useLocalStorage } from "./Hooks/useLocalStorage";
import RoleProtectedRoute from "./Components/Routes/RoleProtectedRoute";

function App() {
  useLocalStorage();

  const ROUTES = useMemo(() => {
    return [
      <Route exact path={WEB_ROUTES.PRIME_TIME} element={<PublicRoute><PrimeTime /></PublicRoute>} />,
      <Route exact path={WEB_ROUTES.PRIME_TIME + '/tournaments/'} element={<PublicRoute><PrimeTime /></PublicRoute>} />,
      <Route exact path={WEB_ROUTES.PRIME_TIME + '/teams/'} element={<PublicRoute><PrimeTime /></PublicRoute>} />,
      <Route exact path={WEB_ROUTES.TOURNAMENT + '/:slug'} element={<PublicRoute><PrimeTime /></PublicRoute>} />,
      <Route exact path={WEB_ROUTES.PRIME_TIME + '/team/:slug'} element={<PublicRoute><PrimeTime /></PublicRoute>} />,
      <Route exact path={WEB_ROUTES.PRIVACY} element={<PublicRoute><PrimeTime /></PublicRoute>} />,
      <Route exact path={WEB_ROUTES.TERMS} element={<PublicRoute><PrimeTime /></PublicRoute>} />,
      <Route exact path={WEB_ROUTES.ACCOUNT} element={<PublicRoute><Account /></PublicRoute>} />,
      <Route exact path={WEB_ROUTES.DASHBOARD} element={<ProtectedRoute><ProtectedLayout selectedRoute={0}><Dashboard /></ProtectedLayout></ProtectedRoute>} />,
      <Route exact path={WEB_ROUTES.MY_TEAM} element={<ProtectedRoute><ProtectedLayout selectedRoute={1} isSettingButton={true}><Teams /></ProtectedLayout></ProtectedRoute>} />,
      <Route exact path={WEB_ROUTES.MY_TOURNAMENTS} element={<ProtectedRoute><ProtectedLayout selectedRoute={2} isSettingButton={true}><Tournaments /></ProtectedLayout></ProtectedRoute>} />,
      // <Route exact path={WEB_ROUTES.MESSAGE} element={<ProtectedRoute><RoleProtectedRoute><ProtectedLayout isSearchBar={false} selectedRoute={3}><Messages /></ProtectedLayout></RoleProtectedRoute></ProtectedRoute>} />,
      <Route exact path={WEB_ROUTES.SETTING} element={<ProtectedRoute><ProtectedLayout isSearchBar={false} selectedRoute={4}><Setting /></ProtectedLayout></ProtectedRoute>} />,
      <Route exact path={WEB_ROUTES.PROFILE} element={<ProtectedRoute><RoleProtectedRoute><ProtectedLayout isSearchBar={false} selectedRoute={5}><Profile /></ProtectedLayout></RoleProtectedRoute></ProtectedRoute>} />,
      <Route path={baseRoute('*')} element={<Navigate to={WEB_ROUTES.PRIME_TIME} />} />
    ];
  }, []);

  return (
    <Routes>
      {ROUTES}
    </Routes>
  );
}

export default App;
