
const ROUTES = {
    PRIMETIME: 'PRIMETIME',
    TEAM_SEARCH: 'TEAM_SEARCH',
    TOURNAMENT_SEARCH: 'TOURNAMENT_SEARCH',
    TOURNAMENT_VIEW: 'TOURNAMENT_VIEW',
    PRIVACY: 'PRIVACY',
    TERMS: 'TERMS',
}

export { ROUTES }