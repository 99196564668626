import React from 'react';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

import styles from "./styles/PrimeTimeLayout.module.scss";


export default function PrimeTimeLayout({
    hasBackButton = false,
    handleBackButton = () => { },
    children,
    registeredPage
}) {
    const [redirect, setRedirect] = useState(false);

    const handleRedirect = () => {
        setRedirect(true);
    };

    if (redirect) {
        return <Navigate to="/account" />;
    }
    
    return (
        <div className={styles.PrimeTimeLayout} style={{ backgroundImage: `url(/images/diamond.jpeg)`, backgroundSize: 'cover' }}>
            {children}
        </div>
    );
}
