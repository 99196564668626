import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { Description, SubTitle } from '../../../Components/CustomComponents';
import cssStyles from "./../content/index.module.css";

const DetailsContent = ({ data = {} }) => {

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
  };

  return (

    <div>
      <Row>
        <Col md={12}>

          <SubTitle
            text='Tournament Description'
            classes='mb-4 mt-2'
          />



          <Description
            text={data?.description}
            classes='my-3 text-dark'
          />

          <Card className={`my-3 ${cssStyles.infoCard}`}>
            <Card.Body>
              <Row>
                <div className="col-md-6">
                    <ul className="list-group">
                        <li className="list-group-item d-flex justify-content-between">
                            <strong>Entry Fee:</strong> <span>${data.ticket_price}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                            <strong>Registration Fee:</strong> <span>${data.registration_fee}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                            <strong>Registration starts:</strong> <span>{formatDateTime(data.registration_start_date)}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                            <strong>Registration Ends:</strong> <span>{formatDateTime(data.registration_end_date)}</span>
                        </li>
                        
                    </ul>
                </div>

                <div className="col-md-6">
                    <ul className="list-group">
                        <li className="list-group-item d-flex justify-content-between">
                            <strong>Sport:</strong> <span>{data.sports_type}</span>
                            <strong>Season:</strong> <span>{data.season}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                            <strong>Location:</strong> <span>{data.location}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                            <strong>State:</strong> <span>{data.state}</span>
                            <strong>Zip:</strong> <span>{data.zip_code}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                            <strong>Start:</strong> <span>{formatDateTime(data.start_date)}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                            <strong>End:</strong> <span>{formatDateTime(data.end_date)}</span>
                        </li>
                    </ul>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default DetailsContent;
