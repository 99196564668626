import React, { useMemo,useEffect,useState } from "react";
import { Col, Row } from "react-bootstrap";
import RegistrationCard from "../../Components/Cards/RegistrationCard";
import cssStyles from "./content/index.module.css";
import Tabs from "../../Components/Tabs/Tabs";
import _ from "lodash";
import { Button, Loader, SubTitle } from "../../Components/CustomComponents";
import DetailsContent from "./content/DetailsContent";
import TeamsContent from "./content/TeamsContent";
import TablesContent from "./content/TablesContent";
import RulesContent from "./content/RulesContent";
import { useQuery } from "react-query";
import { Toast } from "../../Hooks/useToast";
import { useParams } from "react-router-dom";
import { TournamentAPI } from "../../Api/Tournament";

export default function ViewTournamentScreen({ data={}, setPage }) {
  const tournamentAPI = TournamentAPI();
  const [ageGroups, setAgeGroups] = useState([]);
  const [key, setKey] = useState("details");
  const { data: tournament = {}, isError, isLoading } = useQuery({
          queryKey: ['tournament', data.slug],
          queryFn: async () => await tournamentAPI.getByID(data.slug),
          select: (tournamentData) => {
              // Process the age groups
              const processedGroups = (tournamentData.data.age_groups?.map(age => ({
                  label: age?.label,
                  ageSlug: age?.value,
                  id: age?.id
              })) || []).sort((a, b) => a.id - b.id);
              return { ...tournamentData.data, ageGroups: processedGroups };
          },
          onError: (err) => Toast(err.message, 'error', false),
          refetchOnMount: true,
          refetchOnReconnect: true,
          refetchOnWindowFocus: true,
    });
  const array = useMemo(
    () => [
      {
        value: "details",
        label: "Details",
      },
      // {
      //   value: "tables",
      //   label: "Tables",
      // },
      {
        value: "teams",
        label: "Teams",
      },
      {
        value: "rules",
        label: "Rules",
      },
    ],
    []
  );

  useEffect(() => {
    // Update the age groups state when tournament data changes
    if (tournament && tournament.ageGroups) {
        setAgeGroups(tournament.ageGroups);
    }
  }, [tournament]);


  // const handleBackButtonClck = () => {
  //   setPage(ROUTES.TOURNAMENT);
  // };

  const tabContent = () => {
    switch (key) {
      case "details":
        return <DetailsContent data={tournament} />;
      case "tables":
        return <TablesContent groups={ageGroups} />;
      case "teams":
        return <TeamsContent groups={ageGroups} />;
      case "rules":
        return <RulesContent text={tournament?.rules} />;

      default:
        return <DetailsContent />;
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading team data</div>;


  return (
    <div className={cssStyles.ViewTournament}>
      <div className="row d-flex justify-content-between">
        <div className="col-md-6 col-sm-12">
          <Button
            text="Back"
            onClick={() => setPage("Dashboard")}
            classes="btn btn-primary w-auto"
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <h1>{tournament.name}</h1>
        </div>
      </div>
      
      <div className="mt-3">
        <Row>
          <Col
            xs={{ order: 2, span: 12 }}
            sm={{ order: 2, span: 12 }}
            md={{ order: 2, span: 12 }}
            lg={{ order: 1, span: 8 }}
            xl={{ order: 1, span: 8 }}
            xxl={{ order: 1, span: 8 }}
          >
            <div className={cssStyles.beforeTabs}>
              <Tabs tabs={array} current={key} onChange={setKey} />
            </div>

            <div style={{ color: "white" }}>{tabContent()}</div>
          </Col>
          <Col
            xs={{ order: 2, span: 12 }}
            sm={{ order: 2, span: 12 }}
            md={{ order: 2, span: 12 }}
            lg={{ order: 1, span: 4 }}
            xl={{ order: 1, span: 4 }}
            xxl={{ order: 1, span: 4 }}
          >
            <div className={cssStyles.topMargins}>
              <img src={tournament.media_url|| '/images/slide-diamond.png'} alt={tournament.name} className={cssStyles.imageContainer}/>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};