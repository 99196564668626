// Footer.js
import React from 'react';
import { Container, Card, Col, Image, Row, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { WEB_ROUTES } from './constants';

function Footer() {
    const year = new Date().getFullYear();
    return (
        <footer className="text-white text-center py-3" style={{backgroundColor: "#848A93"}}>
            <Container>
                <Row>
                    <Col>
                    <p>&copy; {year} Prime Time Tournaments. All rights reserved.</p>
                    </Col>
                    <Col>
                        <Stack 
                            direction="horizontal"
                            gap={2}
                            style={{ alignItems: "start" }}
                        >
                            <Link to={WEB_ROUTES.PRIVACY} className='text-dark'>Privacy Policy</Link>
                            <Link to={WEB_ROUTES.TERMS} className='text-dark'>Terms of Service</Link>
                            <Link to="https://admin.primetime-tournaments.com" target='_blank' className='text-dark'>Admin</Link>
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;