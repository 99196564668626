import { configureStore } from '@reduxjs/toolkit';

import defaultReducer from './Features/DefaultReducer';
import { AuthApi } from './services/UserService';

export const store = configureStore({
    reducer: {
        default: defaultReducer,
        [AuthApi.reducerPath]: AuthApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(AuthApi.middleware),

})