import React, { useState } from "react";

import styles from "../index.module.scss"

import { Button, CardTitle, ConditionalWrapper, Description, EmptyContainer, Loader, SubTitle } from "../../../Components/CustomComponents";
import { FaMapMarkerAlt } from "react-icons/fa";
import { TeamAPI } from "../../../Api/Team";
import { RefundAPI } from "../../../Api/Refund";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Toast } from "../../../Hooks/useToast";
import { Col, Row } from 'react-bootstrap'
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { WEB_ROUTES } from "../../../constants";
import BorderedCard from "../../../Components/Cards/BorderedCard";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { baseRoute } from "../../../Helper";

export default function Team({ team_slug, setData }) {
    const QUERY_KEY = 'team'
    const teamAPI = TeamAPI();
    const refundAPI = RefundAPI();
    const queryClient = useQueryClient();
    const navigate = useNavigate()

    const [requestSubmitted, setRequestSubmitted] = useState(undefined)

    const { user } = useSelector(state => state.default);
    const { data, isError, isLoading } = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: async () => await teamAPI.get(),
        select: (data) => data.data,
        onSuccess: (data) => {
            data.forEach(item => queryClient.setQueryData([QUERY_KEY + '/' + item.slug], item));
        },
        onError: (err) => Toast(err.message, 'error', false),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
    })

    const refundRequestMutation = useMutation(refundAPI.post, {
        onSuccess: (response, data) => {
            const prev_data = queryClient.getQueryData([QUERY_KEY]);
            queryClient.setQueryData([QUERY_KEY],
                {
                    ...prev_data,
                    data: prev_data?.data.map(item => {
                        if (item.registration_slug !== data.registration_slug) return item;
                        return {
                            ...item,
                            refund_slug: response.data.slug
                        }
                    })
                }
            );

            setRequestSubmitted(undefined)
        },
        onError: (err) => {
            Toast(err.message, 'error', false)
            setRequestSubmitted(undefined)
        }
    });

    const cancelRefundRequestMutation = useMutation(refundAPI.delete, {
        onSuccess: (_, slug) => {
            const prev_data = queryClient.getQueryData([QUERY_KEY]);
            queryClient.setQueryData([QUERY_KEY],
                {
                    ...prev_data,
                    data: prev_data?.data.map(item => {
                        if (item.refund_slug !== slug) return item;
                        return {
                            ...item,
                            refund_slug: ""
                        }
                    })
                }
            );

            setRequestSubmitted(undefined)
        },
        onError: (err) => {
            Toast(err.message, 'error', false)
            setRequestSubmitted(undefined)
        }
    });


    const handleRegisterTeam = () => {
        navigate(`${WEB_ROUTES.PRIME_TIME}/tournaments/`)
    }


    const handleCardClick = (data) => {
        setData(data.slug)
    }

    const handleRefundRequest = (registration_slug) => {
        if (!_.isUndefined(requestSubmitted)) return;

        setRequestSubmitted(registration_slug)
        refundRequestMutation.mutate({ registration_slug })
    }

    const handleCancelRefundRequest = (refund_slug) => {
        if (!_.isUndefined(requestSubmitted)) return;

        setRequestSubmitted(refund_slug)
        cancelRefundRequestMutation.mutate(refund_slug)
    }

    return (
        <div className={styles.Team}>
            <SubTitle
                text={'My Teams'}
                classes='mt-4 mb-1'
            />
            <div className={styles.scrollView}>
            {isLoading ? <Loader /> :
                (isError || _.isEmpty(data)) ? <EmptyContainer component={<SubTitle text="No team found" classes="fs-5 fw-lighter" />} /> :
                    (data ?? []).map((team, index) => {
                        const isActive = team_slug === team.slug
                        return (
                            !team.is_registered ?
                                <ConditionalWrapper
                                    condition={isActive}
                                    wrapper={(child) => <BorderedCard classes="p-0 mt-3 mb-2">{child}</BorderedCard>}
                                >
                                    <div
                                        className={`${styles.ticketContainer} ${!isActive ? 'mt-3 mb-2' : ""} cursor-pointer`}
                                        key={index}
                                        onClick={() => handleCardClick(team)}
                                    >
                                        <div className={styles.teamContainer}>
                                            <div className={styles.team}>
                                                <img
                                                    src={team.image_url??'/images/slide-diamond.png'}
                                                    alt={`Team image ${index}`}
                                                    className={styles.teamLogo}
                                                />
                                                <div className={styles.nameContainer}>
                                                    <CardTitle
                                                        text={team.name}
                                                        classes='lc-1'
                                                    />
                                                    <Row>
                                                        <Col className="col-md-6 col-sm-12">
                                                            <Description
                                                                text={`Age group: ${team.group_title}`}
                                                                classes='fs-9'
                                                            />
                                                        </Col>
                                                        <Col className="col-md-6 col-sm-12">
                                                            <Description
                                                                text={`Sport: ${team.sports_type}`}
                                                                classes='fs-9'
                                                            />
                                                        </Col>
                                                        <Col className="col-md-6 col-sm-12">
                                                            <Description
                                                                text={`Class: ${team.class}`}
                                                                classes='fs-9'
                                                            />
                                                        </Col>
                                                        <Col className="col-md-6 col-sm-12">
                                                            <Description
                                                                text={`${team.member_count} members`}
                                                                classes='fs-9'
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>

                                            </div>


                                        </div>
                                        {!user.is_banned ?
                                            <Button
                                                classes={`${styles.panelBtn} ${styles.cardBtn}`}
                                                style={{ width: '50%' }}
                                                text="Register Team"
                                                onClick={handleRegisterTeam}
                                            ></Button>
                                            : ""}
                                    </div>
                                </ConditionalWrapper>
                            
                                :
                                <ConditionalWrapper
                                    condition={isActive}
                                    wrapper={(child) => <BorderedCard classes="p-0 mt-3 mb-2">{child}</BorderedCard>}
                                >

                                    <div
                                        className={`${styles.ticketContainer} ${!isActive ? "mt-3 mb-2" : ""} cursor-pointer flex-column`}
                                        key={index}
                                        onClick={() => handleCardClick(team)}
                                    >
                                        <div className="w-100 d-flex flex-row align-item-center">
                                            <div className={styles.teamContainer}>
                                                <div className={styles.team}>
                                                    <img
                                                        src={team.image_url}
                                                        alt='Team 01'
                                                        className={styles.teamLogo}
                                                    />
                                                    <div className={styles.nameContainer}>
                                                        <CardTitle
                                                            text={team.name}
                                                            classes='lc-1'
                                                        />
                                                        <Description
                                                            text={`Age group : ${team.group_title}`}
                                                            classes='fs-9'
                                                        />
                                                        <Description
                                                            text={`${team.member_count} member(s)`}
                                                            classes='fs-9'
                                                        />

                                                    </div>

                                                </div>
                                                <div className={styles.tournament}>
                                                    <img
                                                        src={team.tournament_image_url}
                                                        alt='Tournament '
                                                        className={styles.tournamentLogo}
                                                    />
                                                    <div className={styles.nameContainer}>
                                                        <CardTitle
                                                            text={team.tournament_name}
                                                            classes='lc-1'
                                                        />
                                                        <div className={styles.location}>
                                                            <span className={styles.icon}><FaMapMarkerAlt /></span>
                                                            <Description
                                                                text={team.tournament_location}
                                                                classes='fs-9 lc-1'
                                                            />
                                                            {
                                                                !team.is_live ? '' :
                                                                    <span className={styles.liveBadge}>Live</span>
                                                            }
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                            <div className={`${styles.qrContainer} justify-content-center`}>
                                                <img
                                                    src='/images/qr.png'
                                                    alt='QR Code'
                                                    className={styles.qrImage}
                                                // onClick={handleModalOpen}
                                                />
                                                <Description
                                                    text='Team QR'
                                                    classes='fs-8 text-center mt-2 text-nowrap'
                                                />

                                            </div>
                                        </div>
                                        <div className='d-flex flex-row w-100 justify-content-center'>
                                            {team.refund_slug ?
                                                <Button
                                                    text={
                                                        ((requestSubmitted === team.refund_slug) || (requestSubmitted === team.registration_slug)) ? < Spinner size="sm" /> : "Cancel request"
                                                    }
                                                    classes="w-50 fs-6 bg-red"
                                                    onClick={() => handleCancelRefundRequest(team.refund_slug)}

                                                /> :
                                                <Button
                                                    text={((requestSubmitted === team.refund_slug) || (requestSubmitted === team.registration_slug)) ? < Spinner size="sm" /> : "Refund request"}
                                                    classes="w-50 fs-6"
                                                    onClick={() => handleRefundRequest(team.registration_slug)}
                                                />
                                            }

                                        </div>
                                    </div>
                                </ConditionalWrapper>

                        )
                    })
            }
            </div>
        </div >

    )
}