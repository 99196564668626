import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FaCircleXmark } from 'react-icons/fa6';
import SelectFieldComponent from '../../Pages/Tournaments/Components/SelectFieldComponent';
import { Button } from '../CustomComponents';
import ModalContainer from '../ModalContainer';

import filterStyles from "./styles/filter.module.css";

// date picker
import "react-datepicker/dist/react-datepicker.css";
import { useQuery } from 'react-query';
import { AgeGroupAPI } from '../../Api/AgeGroup';
import { SPORTS_TYPE } from '../../constants';


const FilterModal = ({ data, setData, handleClose, handleSubmit, handleClear }) => {
    const ageGroupAPI = AgeGroupAPI()
    const QUERY_KEY = 'user/age-group';


    const { data: ageGroupData = [] } = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: async () => await ageGroupAPI.get(),
        select: (data) => data.data,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    })

    const handleSportChange = (value) => {
        setData(prev => {
            return {
                ...prev,
                selectedSport: value
            }
        });
    };

    const handleAgeChange = (value) => {
        setData(prev => {
            return {
                ...prev,
                selectedAge: value
            }
        });
    };

    const handleChangeStartDate = (value) => {
        setData(prev => {
            return {
                ...prev,
                startDate: value
            }
        });
    };

    const nameList = [
        { label: "Baseball", value: SPORTS_TYPE.BASEBALL },
        { label: "Softball", value: SPORTS_TYPE.SOFTBALL }
    ];

    // <DateFieldComponent value={startDate} onChange={handleChangeStartDate} />

    // <DatePicker showIcon toggleCalendarOnIconClick selected={startDate} onChange={(date) => setStartDate(date)} />


    return (
        <div>
            <ModalContainer
                handleClose={handleClose}
            >
                <div className={filterStyles.modalContainer}>

                    <div className='d-flex align-items-center w-100 justify-content-between mb-4'>
                        <p className='fs-4 fw-medium m-0'>
                            Filter
                        </p>
                        <FaCircleXmark onClick={handleClose} style={{ cursor: 'pointer' }} />
                    </div>

                    <Row style={{ width: '100%' }} >

                        <Col md={6} className="">
                            <p className="m-0" style={{ color: '#0AA7FF' }}>
                                Sports
                            </p>

                            <SelectFieldComponent firstOption={data.selectedSport ? data.selectedSport : 'Select Sport'} optionsList={nameList} onSelectChange={handleSportChange} />

                            <p className="m-0" style={{ color: '#0AA7FF' }}>
                                Age Group
                            </p>
                            <SelectFieldComponent
                                firstOption={data.selectedAge ? data.selectedAge : 'Select Age Group'}
                                optionsList={ageGroupData ?? []}
                                onSelectChange={handleAgeChange}
                            />

                            {/* <Row>
                                <Col md={6}>

                                    <p className="m-0" style={{ color: '#0AA7FF' }}>
                                        Zip Code
                                    </p>
                                    <Input classes="mt-2 mb-3" placeholder="Enter Zip Code" value={zipCode} onChange={handleChangeZipCode} />
                                </Col>
                                <Col md={6}>
                                    <p className="m-0" style={{ color: '#0AA7FF' }}>
                                        Range
                                    </p>
                                    <SelectFieldComponent firstOption={selectedRange ? selectedRange : 'Select Range'} optionsList={rangeList} onSelectChange={handleRangeChange} />
                                </Col>
                            </Row> */}
                        </Col>
                        <Col md={6} className="">
                            {/* <p className="m-0" style={{ color: '#0AA7FF' }}>
                                Season
                            </p>
                            <SelectFieldComponent firstOption={selectedSeason ? selectedSeason : 'Select Season'} optionsList={seasonList} onSelectChange={handleSeasonChange} /> */}
                            <p className="m-0" style={{ color: '#0AA7FF' }}>
                                Start Date
                            </p>
                            <div className='mt-2'>
                                <Form.Control
                                    className={`${filterStyles.datePicker}`}
                                    name="start_date"
                                    type="date"
                                    // type="text"
                                    // onFocus={(e) => e.target.type = 'date'}
                                    // onBlur={(e) => e.target.type = 'text'}
                                    placeholder='Select Date'
                                    value={data.startDate}
                                    onChange={(e) => handleChangeStartDate(e.target.value)}
                                />

                            </div>
                            {/* <Row className="mt-2">
                                <Col md={6}>
                                    <p className="m-0" style={{ color: '#0AA7FF' }}>
                                        State
                                    </p>
                                    <SelectFieldComponent firstOption={selectedState ? selectedState : 'Select State'} optionsList={stateList} onSelectChange={handleStateChange} />
                                </Col>
                                <Col md={6}>
                                    <p className="m-0" style={{ color: '#0AA7FF' }}>
                                        Director
                                    </p>
                                    <SelectFieldComponent firstOption={selectedDirector ? selectedDirector : 'Select Director'} optionsList={directorList} onSelectChange={handleDirectorChange} />
                                </Col>
                            </Row> */}

                        </Col>
                    </Row>
                    <Row style={{ width: '100%' }}>
                        <Col sm={6} xs={12}>
                            <Button
                                style={{ background: "rgb(233, 91, 91)", border: "none" }}
                                classes={`${filterStyles.cardBtn} mt-4 `}
                                text="Clear Filter"
                                onClick={handleClear}
                            ></Button>
                        </Col>
                        <Col sm={6} xs={12}>
                            <Button
                                classes={`${filterStyles.cardBtn} mt-4 `}
                                text="Filter"
                                onClick={handleSubmit}
                            ></Button>
                        </Col>
                    </Row>

                </div>
            </ModalContainer >
        </div>
    )
}

export default FilterModal
