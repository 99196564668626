import React, { useState }from "react";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { Button, Description, Input, Title } from "../../Components/CustomComponents";
import { ACCOUNT_ENUM } from "./Route";
import { useForgotPasswordMutation } from "../../services/UserService";


export default function ForgotPassword({ setPage }) {
    const [forgotPW] = useForgotPasswordMutation()
    const [email, setEmail] = useState('')
    const handleBackButtonClick = () => {
        setPage(ACCOUNT_ENUM.LOGIN)
    }

    const handleInputChange = (name, value) => {
        setEmail(value)
    }

    const handleSubmit = async () => {
        const data = {
            email: email
        }
        const response = await forgotPW(data).unwrap();
        if (response.status === 200) setPage(ACCOUNT_ENUM.OTP, { email: data.email});
    }

    return (
        <AccountLayout
            hasBackButton={true}
            handleBackButton={handleBackButtonClick}
        >
            <Title text="Forgot Password" classes="mb-2" />
            <Description
                text="In order to reset your password you need to enter your registered email."
                classes="text-center fs-7"
            />
            <Input
                placeholder="Email Address"
                classes="mt-4 mb-5"
                value={email}
                onChange={handleInputChange}
            />
            <Button
                text='Continue'
                classes="mt-3 mb-2"
                onClick={handleSubmit}
            />

        </AccountLayout>
    )
}