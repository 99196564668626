import React, { useEffect, useState, useCallback } from "react";
import { TeamAPI } from "../../Api/Team";
import { useQuery } from "react-query";
import { Toast } from "../../Hooks/useToast.js";
import { Link, useNavigate } from "react-router-dom";
import { WEB_ROUTES } from "../../constants";
import { baseRoute } from "../../Helper";
import styles from "../../Components/Layouts/styles/PrimeTimeLayout.module.scss";
import Default from '../../assets/images/default.jpg';
import { set } from "lodash";
import debounce from "lodash.debounce";


export default function TeamSearch() {
   
    const teamAPI = TeamAPI();
    const [filters, setFilters] = useState({});
    const [paginationDetail, setPaginationDetail] = useState([]);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [teams, setTeams] = useState([]);
    const [debouncedFilters, setDebouncedFilters] = useState({});

    const updateFilters = useCallback(
        debounce((newFilters) => {
          setDebouncedFilters(newFilters);
          setPage(1); // Reset to the first page on filter change
        }, 300),
        []
    );

    useEffect(() => {
        updateFilters({ search, ...filters });
    }, [search, filters, updateFilters]);
    
    const { data, isError, isLoading } = useQuery(
        ['teams', page, debouncedFilters],
        async () => await teamAPI.getTeams(page, 8, debouncedFilters),
        {
            onSuccess: (data) => {
                setTeams(data.data);
                setPaginationDetail(data.links);
                setPage(data.links.current);
            },
            onError: (err) => Toast(err.message, 'error', false),
            refetchOnMount: true,
            refetchOnReconnect: true,
            refetchOnWindowFocus: true,
        }
    );

    const handleFilterChange = (key, value) => {
        setFilters((prev) => ({ ...prev, [key]: value }));
    };

    const handleResetFilters = () => {
        setSearch("");
        setFilters({});
    };

    const handlePreviousPage = () => {
        if (paginationDetail?.current > 1) {
            setPage((prev) => prev - 1);
        }
    };
      
    const handleNextPage = () => {
        if (paginationDetail?.current < paginationDetail?.total) {
            setPage((prev) => prev + 1);
        }
    };


    return (
        <div className={styles.sectionContainer}>
            {/* Search Input */}
            <div className="row d-flex justify-content-center">
                <div className="col-md-6 col-sm-12 mb-2">
                    <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search for Teams..." className="form-control"
                        style={{ borderRadius: '25px', border: '1px solid #ccc', fontSize: '16px', outline: 'none', background:"#edeff3", }}
                    />
                </div>

                <div className="col-md-2 col-sm-6 d-flex justify-content-evenly">
                    <button className={`btn ${styles.gdBtn}`} onClick={handleResetFilters} >
                        Reset
                    </button>
                </div> 
            </div>

            <div className="row mb-4 d-flex justify-content-center">
                {/* Sport Dropdown */}
                <div className="col-md-2 col-sm-12">
                    <label style={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}>Sport</label>
                    <select
                        onChange={(e) => handleFilterChange("sports_type", e.target.value)}
                        style={{ width: '100%', padding: '10px', marginTop: '5px', borderRadius: '5px', border: '1px solid #ccc', fontSize: '16px', background:"#edeff3",}}
                        id="sport"
                    >
                        <option value="">Select Sport</option>
                        <option value="Fast Pitch">Fast Pitch</option>
                        <option value="Baseball">Baseball</option>
                    </select>
                </div>
                {/* Season Dropdown */}
                <div className="col-md-2 col-sm-12">
                    <label style={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}>Season</label>
                    <select
                        onChange={(e) => handleFilterChange("season", e.target.value)}
                        style={{ width: '100%', padding: '10px',  marginTop: '5px', borderRadius: '5px', border: '1px solid #ccc', fontSize: '16px', background:"#edeff3", }}
                        id="season"
                    >
                        <option value="">Select Season</option>
                        <option value="2025">2025 Season</option>
                        <option value="2024">2024 Season</option>
                        <option value="2023">2023 Season</option>
                    </select>
                </div>

                <div className="col-md-2 col-sm-12">
                    <label style={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}>Class</label>
                    <select
                        onChange={(e) => handleFilterChange("class", e.target.value)}
                        
                        style={{ width: '100%', padding: '10px', marginTop: '5px', borderRadius: '5px', border: '1px solid #ccc', fontSize: '16px', background:"#edeff3",}}
                    >
                        <option value="">Select Class</option>
                        <option value="A">Class A</option>
                        <option value="B">Class B</option>
                        <option value="C">Class C</option>
                        <option value="REC">Rec/All-Star League</option>
                        <option value="2023">Other</option>
                    </select>
                </div>
            </div>

            {/* Team List */}
            <div className="container">
                                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                    <div className="row">
                        {teams.length > 0 ? (
                            <>
                                {teams.map((team) => (
                                    <div key={team.id} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                                        <div className="card h-100" style={{ borderRadius: '10px', background: '#848a93' }}>
                                            <img 
                                                className="card-img-top" 
                                                src={team.image_url || Default} 
                                                style={{ maxWidth: '100%', height: '250px', objectFit: 'contain', borderRadius: '4px', background: '#fff' }} 
                                                alt="Team"
                                            />
                                            <div className="card-body">
                                                <h5 className="text-light mb-2">{team.name}</h5>
                                                <div className="row">
                                                    <div className="col-6 text-light">{team.sports_type}</div>
                                                    <div className="col-6 text-light text-end">{team.age?.title}</div>
                                                    <div className="col-6 text-light">{team.coach_name}</div>
                                                </div>
                                                <Link to={`${WEB_ROUTES.PRIME_TIME}/team/${team.slug}`}>
                                                    <button className={`btn mt-3 ${styles.gdBtn}`}>
                                                        View
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-2 col-sm-6 d-flex justify-content-evenly">
                                        <button
                                            onClick={handlePreviousPage}
                                            className={`btn ${styles.gdBtn}`}
                                            disabled={page === 1}
                                        >
                                            Previous
                                        </button>
                                        <span className="text-dark">{page}/{paginationDetail.total}</span>
                                        <button
                                            onClick={handleNextPage}
                                            className={`btn ${styles.gdBtn}`}
                                            disabled={page === paginationDetail.total}
                                        >
                                            Next
                                        </button>
                                    </div> 
                                </div>
                            </>
                        ) : (
                            <p>No teams</p>
                        )}
                    </div>
                )}
            </div>         
        </div>

    );
}
