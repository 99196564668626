import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import TeamSelectionCard from './TeamSelectionCard'
import { useQuery } from 'react-query';
import { PlayerAPI } from '../../../Api/Player';
import { Toast } from '../../../Hooks/useToast';
import _ from 'lodash';
import { Description, EmptyContainer, Loader, SubTitle } from '../../../Components/CustomComponents';


const TeamSelection = ({
    ageGroup = undefined,
    sport,
    setPlayerCount = () => { },
    selectedItems = [],
    setSelectedItems = () => { }
}) => {
    const QUERY_KEY = 'age-group/' + ageGroup + '/'+ sport + '/team/unregister'
    const playerAPI = PlayerAPI()

    const { data, isError, isLoading } = useQuery({
        queryKey: [QUERY_KEY],
        enabled: !_.isUndefined(ageGroup),
        queryFn: async () => await playerAPI.getAgeGroupUnregisterPlayer(ageGroup, sport),
        select: (data) => data.data,
        onError: (err) => Toast(err.message, 'error', false),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
    })
    const handleSelectPlayer = (slug) => {
        if (selectedItems.includes(slug)) {
            setSelectedItems(prv => {
                return prv.filter(i => i !== slug);
            });

            setPlayerCount(prv => prv - 1);

        } else {
            setSelectedItems(prv => {
                return [...prv, slug];
            });

            setPlayerCount(prv => prv + 1);
        }
    };

    return (
        isLoading ? <Loader /> :
            (isError || _.isEmpty(data)) ?
                <EmptyContainer component={<SubTitle text='Player not found for this age group' classes='fs-5' />} /> :
                <Row>
                    {(data ?? []).map((player, index) => (
                        <Col className='p-3' key={index} xxl={6} md={6} sm={12}  >
                            <TeamSelectionCard
                                data={player}
                                isSelected={selectedItems.includes(player.slug)}
                                onSelect={() => handleSelectPlayer(player.slug)}
                                selected={selectedItems}
                            />
                        </Col >
                    ))
                    }
                </Row >
    )
}

export default TeamSelection
