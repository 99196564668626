import React from 'react';

import styles from "./styles/LiveMatches.module.scss"

import { BackButton, Description, EmptyContainer, Loader, SubTitle } from '../../Components/CustomComponents';
import { Row, Col, Card, Button } from 'react-bootstrap';
import Default from '../../assets/images/default.jpg';
import { ROUTES } from './constants';
import { useQuery, useQueryClient } from 'react-query';
import { Toast } from '../../Hooks/useToast';
import _ from 'lodash';
import { TournamentAPI } from '../../Api/Tournament';
import TournamentCard from '../../Components/Cards/TournamentCard';


export default function TournamentListing({ setPage }) {
    const tournamentAPI = TournamentAPI()
    const queryClient = useQueryClient();

    const { data, isLoading, isError } = useQuery({
        queryKey: ['tournament/upcoming'],
        queryFn: async () => await tournamentAPI.getUpcomingTournaments(),
        onSuccess: (data) => {
            data.forEach(item => queryClient.setQueryData(['tournament/' + item.slug], item));
        },
        select: (data) => data.data,
        onError: (err) => Toast(err.message, 'error', false),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
    })


    const handleBackButtonClick = () => {
        setPage(ROUTES.DETAILS)
    }

    const handleTournamentClick = (data) => {
        const payload = {
            ...data,
            previous_route: ROUTES.TOURNAMENT_LISTING
        }
        setPage(ROUTES.TOURNAMENTS, payload)
    }


    return (
        <div className={styles.LiveMatches}>
            <BackButton isRelative={true} handleBackButton={handleBackButtonClick} />

            <SubTitle
                text={'Upcoming Tournaments'}
            />
            <Row
                className={`${styles.matchRow} w-100 mt-2 mb-2`}
                gutter={[0, 24]}
            >
                {isLoading ? <Loader /> :
                    (isError || _.isEmpty(data)) ?
                    <EmptyContainer component={<SubTitle text='No Tournaments yet' classes='fs-5 fw-lighter' />} /> :
                    data.map((tournament, index) => (
                        <div className="item m-1" key={index}>
                            <Col key={index} xs={12} sm={6} md={4} className="mb-4">
                                <Card className={styles.sectionContentBodyRowItem}>
                                    <Card.Body>
                                        <Card.Img variant="top" src={tournament.media_url || Default} style={{ maxWidth: '100%', height: '250px', objectFit: 'contain', borderRadius: '4px' }} />
                                        <Card.Title>{tournament.name}</Card.Title>
                                        <Card.Text>{tournament.class} - {tournament.sports_type}</Card.Text>
                                        <Card.Text>{tournament.description}</Card.Text>
                                        <Button variant="primary" onClick={() => handleTournamentClick(tournament)}>View</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </div>
                    ))
                }
            </Row>
        </div >
    )
}

