import React, { useMemo, useState } from "react";
import cssStyles from "./index.module.css";
import { ROUTES } from "./Route.js";

import _ from "lodash";
import { Card, Col, Image, Row, Stack } from "react-bootstrap";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import CustomBadge from "../../Components/Badges/CustomBadge.js";
import { Button, EmptyContainer, Loader, SubTitle, Title } from "../../Components/CustomComponents/index.js";
import { CardTitle } from "../../Components/CustomComponents/index.js";
import { TeamAPI } from "../../Api/Team.js";
import FilterModal from "../../Components/Modals/FilterModal.js";
import { Toast } from "../../Hooks/useToast.js";
import { setFilterModal } from "../../Features/DefaultReducer.js";
import Default from "../../assets/images/default.jpg";

export default function TeamScreen({ setPage }) {
  const teamAPI = TeamAPI();
  const dispatch = useDispatch();
  const user_type = useSelector(state => state.default.route);
  const { user } = useSelector(state => state.default)
  const isFilterModal = useSelector(state => state.default.isFilterModal);

  const INITIAL_FILTER_DATA = useMemo(() => ({
    selectedSport: "",
    selectedAge: "",
    startDate: ""
  }), []);

  const [filterData, setFilterData] = useState(INITIAL_FILTER_DATA);
  const [teams, setTeams] = useState([]);

  const { data, isError, isLoading } = useQuery({
    queryKey: [user_type + '/teams/'],
    queryFn: async () => await teamAPI.get(),
    select: (data) => data.data,
    onSuccess: (data) => {
      setTeams(data);
      setFilterData(INITIAL_FILTER_DATA);
    },
    onError: (err) => Toast(err.message, 'error', false),
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  const handleViewTeam = (index) => {
    const record = teams[index];
    setPage(ROUTES.VIEW_TEAM, { teamId: record.slug, details: record });
  };

  const handleFilterModalClose = () => {
    dispatch(setFilterModal(false));
  };

  const handleFilterApplied = () => {
    if (!_.isEmpty(data)) {
      setTeams(data.filter(item => {
        if (filterData.selectedSport && item.sports_type === filterData.selectedSport) return true;
        if (filterData.selectedAge && item.age_groups.includes(filterData.selectedAge)) return true;
        return false;
      }));
    }
    handleFilterModalClose();
  };

  const handleClearFilter = () => {
    setTeams(data);
    setFilterData(INITIAL_FILTER_DATA);
    handleFilterModalClose();
  };

  return (
    <div className={cssStyles.team}>
      {isFilterModal && <FilterModal handleClose={handleFilterModalClose} data={filterData} setData={setFilterData} handleSubmit={handleFilterApplied} handleClear={handleClearFilter} />}

      <Title text="My Teams" classes="mb-2 text-dark" />

      {isLoading ? <Loader /> :
        (isError || _.isEmpty(teams)) ? (
          <EmptyContainer component={<SubTitle text="Teams not found" classes="fs-5 fw-lighter" />} />
        ) : (
          <Row>
            {teams.map((record, idx) => (
              <Col key={idx} sm={12} md={6} lg={4} className="mb-1">
                <div className={cssStyles.cardStyle}>
                <Row noGutters className="align-items-center">
                  <Col xs={2} className="d-flex justify-content-center">
                    <Image src={record.image_url||Default} roundedCircle className={cssStyles.avatarImage} style={{ width: "50px", height: "50px" }} />
                  </Col>
                  <Col xs={10}>
                    <CardTitle text={record.name} classes="fs-4 mb-2" />
                  </Col>
                </Row>
                <Row noGutters className="align-items-center">
                  <Col xs={6} className="d-flex justify-content-center">
                    <CustomBadge text={`Members: ${record.member_count}`} />
                  </Col>
                  <Col xs={6}>
                    <CustomBadge text={`Coach: ${record.coach_name}`} />
                  </Col>
                </Row>
                <Button
                  classes={`${cssStyles.cardBtn} mt-3`}
                  text="View Team Details"
                  onClick={() => handleViewTeam(idx)}
                  style={{ width: "100%" }}
                />
                </div>
              </Col>
            ))}
          </Row>
        )}
    </div>
  );
}
