import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Tabs from '../../Components/Tabs/Tabs'
import TreeComponent from './TreeComponent';
import _ from 'lodash';
import { TournamentAPI } from '../../Api/Tournament';
import { useQuery } from 'react-query';
import { Toast } from '../../Hooks/useToast';
import { Description, EmptyContainer, Loader } from '../../Components/CustomComponents';


const TablesContent = ({ groups = [] }) => {
  const [key, setKey] = useState(groups.length ? groups[0].value : '');
  const tournamentAPI = TournamentAPI();
  const QUERY_KEY = 'tournament/age-group/' + key + '/fixture'

  const inlineItemStyles = {
    background: '#222232',
    margin: '5px',
    padding: '10px 20px',
  };


  const { data, isError, isLoading } = useQuery({
    queryKey: QUERY_KEY,
    enabled: !_.isEmpty(key),
    queryFn: async () => await tournamentAPI.getFixtureByID(key),
    select: (res) => res.data,
    onError: (err) => Toast(err.message, 'error', false),
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })


  return (
    <div className='p-2'>
      <p className="text-white m-0 fw-bold fs-5">
        Team Age Group
      </p>

      <Row>
        <Col className='p-2'>
          <Tabs tabs={groups} current={key} onChange={setKey} itemStyle={inlineItemStyles} containerStyles={{ width: 'fit-content' }} />
        </Col>
      </Row>

      {
        //tabContent() 
      }


      <Row>
        <Col className='col-12'>
          {isLoading ? <Loader /> :
            (isError || _.isEmpty(data)) ? <EmptyContainer component={<Description text={"Tournament not fixtured yet"} classes='fs-3' />} /> :
              <TreeComponent data={data} />
          }
        </Col>
      </Row>


    </div>
  )
}

export default TablesContent
