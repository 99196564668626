import React, { useState } from 'react';
import { ROUTES } from './constants';
import Dashboard from './Dashboard';
import LiveMatches from './LiveMatches';
import Tournament from './Tournament';
import TournamentListing from './TournamentListing';
import ViewTournamentScreen from './ViewTournament';


export default function Index() {
    const [page, setPage] = useState({
        route: ROUTES.DETAILS,
        data: {}
    });

    const handlePageChange = (route, data = {}) => {
        setPage(prev => {
            return {
                ...prev,
                route: route,
                data: data
            }
        })
    }


    switch (page.route) {
        case ROUTES.DETAILS:
            return <Dashboard setPage={handlePageChange} />
        case ROUTES.LIVE_MATCHES:
            return <LiveMatches setPage={handlePageChange} />
        case ROUTES.TOURNAMENT_LISTING:
            return <TournamentListing setPage={handlePageChange} />
        case ROUTES.TOURNAMENTS:
            return <Tournament data={page.data} setPage={handlePageChange} />
        case ROUTES.VIEW_TOURNAMENT:
            return <ViewTournamentScreen data={page.data} setPage={handlePageChange} />
        default:
            return <Dashboard setPage={handlePageChange} />
    }


}

