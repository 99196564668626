// Header.js
import styles from './Components/Layouts/styles/HomeNav.module.scss';
import React, { useMemo, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setFilterModal } from "./Features/DefaultReducer";
import { useQueryClient } from "react-query";
import { logout } from "./Features/DefaultReducer";
import { GiHamburgerMenu } from "react-icons/gi";
import { WEB_ROUTES } from "./constants";

function Header() {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const queryClient = useQueryClient()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const menuRef = useRef();
    const { isLoggedIn } = useSelector(state => state.default);

    const items = useMemo(() => {
        return [
            {
                label : "Tournaments",
                value: "Tournaments"
            },
            {
                label : "Teams",
                value: "Teams"
            },
            !isLoggedIn && {
                label : "Account",
                value: "Login"
            },
            isLoggedIn && {
                label : "Dashboard",
                value: "Dashboard"
            },
        ].filter(Boolean);
    }, [isLoggedIn]);

    const handleFilterModalOpen = () => {
        dispatch(setFilterModal(true));
    };
    
    const handleLogout = () => {
        dispatch(logout())
        queryClient.clear()
        if (window.location.pathname !== `${process.env.REACT_APP_DOMAIN_DIR}/`) {
            navigate(`${process.env.REACT_APP_DOMAIN_DIR}/`)
        }
    }

    const handleSwitch = () => {
        
    }

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen); // Toggle the mobile menu state
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        };

        if (isMenuOpen) {
            document.addEventListener("mousedown", handleOutsideClick);
        } else {
            document.removeEventListener("mousedown", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [isMenuOpen]);
    
  return (
    <div className={styles.Navbar} ref={menuRef}>
        <div className={styles.leftContainer}>
            <a href={`/`}>
                <img src="/images/logo.png" alt="Logo" className={styles.logo} />
            </a>
        </div>

        <div className={`${styles.rightContainer} ${isMenuOpen ? styles.mobileMenu : ""}`}>
            {items.map(({ value, label }, index) => {
                const linkPath =
                    label === "Dashboard" || label === "Account"
                        ? `/${label.toLowerCase().replace(/[^A-Z0-9]/ig, "-")}`
                        : `${WEB_ROUTES.PRIME_TIME}/${label.toLowerCase().replace(/[^A-Z0-9]/ig, "-")}/`;

                return (
                    <Link
                        to={linkPath}
                        className={`${label === 'Dashboard' || label === 'Account' ? styles.itemLogin : styles.item} btn`}
                        key={index}
                    >
                        <span style={{ fontSize: "20px", display: "flex" }}>{value}</span>
                    </Link>
                );
            })}

            {/* <div className={styles.notificationButton} onClick={handleNotificationModalOpen}>
                <IoIosNotificationsOutline />
            </div> */}
        </div>

        {/* Hamburger Icon */}
        <div className={styles.toggleButton} onClick={toggleMenu}>
            <GiHamburgerMenu />
        </div>
    </div>
  );
}

export default Header;