import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { Description, SubTitle } from '../../Components/CustomComponents';

const DetailsContent = ({ data = {} }) => {

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
  };

  return (

    <div>
      <Row>
        <Col md={12}>

          <SubTitle
            text='Tournament Description'
            classes='mb-4 mt-2'
          />



          <Description
            text={data?.description}
            classes='my-3'
          />

          <Card className='my-3'>
            <Card.Body>
            <Row>
                <Col>
                  <SubTitle
                    text='Tournament Start Date'
                    classes='mb-4 mt-2'
                  />
                  <Description
                    text={formatDateTime(data?.start_date)}
                    classes='my-3'
                  />
                </Col>
                <Col>
                  <SubTitle
                    text='Tournament End Date'
                    classes='mb-4 mt-2'
                  />
                  <Description
                    text={formatDateTime(data?.end_date)}
                    classes='my-3'
                  />
                </Col>
              </Row><Row>
                <Col>
                  <SubTitle
                    text='Tournament Start Date'
                    classes='mb-4 mt-2'
                  />
                  <Description
                    text={data?.state}
                    classes='my-3'
                  />
                </Col>
                <Col>
                  <SubTitle
                    text='Tournament End Date'
                    classes='mb-4 mt-2'
                  />
                  <Description
                    text={data?.zip_code}
                    classes='my-3'
                  />
                </Col>
              </Row><Row>
                <Col>
                  <SubTitle
                    text='Tournament Start Date'
                    classes='mb-4 mt-2'
                  />
                  <Description
                    text={data?.location}
                    classes='my-3'
                  />
                </Col>
                <Col>
                  <SubTitle
                    text='Tournament End Date'
                    classes='mb-4 mt-2'
                  />
                  <Description
                    text={data?.season}
                    classes='my-3'
                  />
                </Col>
              </Row><Row>
                <Col>
                  <SubTitle
                    text='Tournament Start Date'
                    classes='mb-4 mt-2'
                  />
                  <Description
                    text={data?.sports_type}
                    classes='my-3'
                  />
                </Col>
                <Col>
                  <SubTitle
                    text='Tournament End Date'
                    classes='mb-4 mt-2'
                  />
                  <Description
                    text={formatDateTime(data?.registration_start_date)}
                    classes='my-3'
                  />
                </Col>
              </Row><Row>
                <Col>
                  <SubTitle
                    text='Tournament Start Date'
                    classes='mb-4 mt-2'
                  />
                  <Description
                    text={formatDateTime(data?.registration_end_date)}
                    classes='my-3'
                  />
                </Col>
                <Col>
                  <SubTitle
                    text='Tournament End Date'
                    classes='mb-4 mt-2'
                  />
                  <Description
                    text={data?.registration_fee}
                    classes='my-3'
                  />
                </Col>
              </Row><Row>
                <Col>
                  <SubTitle
                    text='Tournament Start Date'
                    classes='mb-4 mt-2'
                  />
                  <Description
                    text={data?.ticket_price}
                    classes='my-3'
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default DetailsContent;
