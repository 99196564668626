import React, { useEffect, useState } from 'react'
import Tabs from '../../Components/Tabs/Tabs'
import { Col, Row } from 'react-bootstrap';
import TeamsTabContent from './TeamsTabContent';
import { TournamentAPI } from '../../Api/Tournament';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { Toast } from '../../Hooks/useToast';
import { Link, useNavigate, useParams } from "react-router-dom";
import { WEB_ROUTES } from "../../constants";
import { baseRoute } from "../../Helper";
import _ from 'lodash';
import { SubTitle } from '../../Components/CustomComponents';
import styles from "../../Components/Layouts/styles/PrimeTimeLayout.module.scss";

const TournamentTeams = ({ ageGroups = [] }) => {
    const { route: base_route } = useSelector(state => state.default);
    const tournamentAPI = TournamentAPI();
    const [key, setKey] = useState(ageGroups.length ? ageGroups[0].ageSlug : '');
    const [teams, setTeams] = useState([]);

    const fetchTeams = async (groupSlug) => {
        setKey(groupSlug);
        try {
            if (!groupSlug) {
                console.error("No group slug provided.");
                return;
            }

            const response = await tournamentAPI.getRegisteredTeamsByID(groupSlug);

            if (!response || response.error) {
                console.error("Error fetching teams:", response.error || "Unknown error");
                return;
            }

            const responseTeams = response.map(team => team.Registration_TeamSlug);
            setTeams(responseTeams);
        } catch (error) {
            console.error("Error in fetchTeams function:", error);
        }
    };

    useEffect(() => {
        if (!_.isEmpty(key)) {
            fetchTeams(key);
        }
    }, [key])


    return (
        <div className='p-2'>
        {/* <SubTitle text='Teams Age Group' /> */}
        <SubTitle
            text='Tournament Teams'
            classes='mb-4 mt-2'
          />
        {/* <Row>
            <Col className='p-2'>
            <Tabs tabs={ageGroups} current={key} onChange={setKey} itemStyle={inlineItemStyles} containerStyles={{ width: 'fit-content' }} />
            </Col>
        </Row> */}

            {/* Age Groups */}
            <div className="container text-center">
                <h3>Age Groups</h3>
                <div className="btn-toolbar d-flex justify-content-center" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group" role="group" aria-label="Basic example">
                        {ageGroups?.map((group, index) => (
                            <button key={index} className={`${styles.tab} btn ${(key === group.ageSlug) ? styles.active : ''}`} onClick={() => setKey(group.ageSlug)} >
                                {group.label}
                            </button> 
                        ))}
                    </div>
                </div>
            </div>

            {/* Teams */}
            <div className={`${styles.infoCard} mt-3`}>
                <div style={{ padding: '20px', borderRadius: '10px', }}>
                    <h3>Teams</h3>
                    {teams.length === 0 ? (
                        <div>No teams available</div>
                    ) : (
                        <table className="table table-striped" style={{ border: '1px solid black' }}>
                            <thead>
                                <tr>
                                    <th>Team Name</th>
                                    <th>Sport</th>
                                    <th>Coach</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {teams.map((team, index) => (
                                    <tr key={index}>
                                        <td>{team.name}</td>
                                        <td>{team.sports_type}</td>
                                        <td>{team.Team_UserSlug?.firstname} {team.Team_UserSlug?.lastname}</td>
                                        <td>
                                            <Link to={`${WEB_ROUTES.PRIME_TIME}/team/${team.slug}`}>
                                                <button className="btn btn-primary btn-sm">
                                                    View
                                                </button>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
       </div>
    )
}

export default TournamentTeams
