import React, { useEffect, useState } from 'react';
import { Seed, SeedItem, RenderSeedProps } from 'react-brackets';
import medalImage from '../../../assets/images/Medal.png';
import './CustomSeed.css'; // Import the CSS file

const formatDateForInput = (dateString) => {
    if (!dateString) {
        return '';
    }
    const date = new Date(dateString);
    const cstDate = new Date(date.getTime() - 6 * 60 * 60 * 1000); // Subtract 6 hours for CST
    return cstDate.toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });
};

const CustomSeed = ({ seed, breakpoint }: RenderSeedProps) => {
    const [startDate, setStartDate] = useState(formatDateForInput(seed.startDate || ''));
    const [field, setField] = useState(seed.field || '');

    useEffect(() => {
        setStartDate(formatDateForInput(seed.startDate || ''));
        setField(seed.field|| '');
    }, [seed.startDate, seed.field]);

    return (
        <Seed mobileBreakpoint={breakpoint} >
            <SeedItem className="seed-item">
                <div className="row position-relative">
                    <div className="col-9 text-left">
                        {seed.teams[0]?.name || 'No Team'}
                        {seed.winner_slug === seed.teams[0]?.id && (
                            <img
                                src={medalImage}
                                alt="Winner"
                                className="winner-medal"
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    right: '0',
                                    width: '20px',
                                    height: '20px',
                                }}
                            />
                        )}
                    </div>
                    <div className="col-3 text-right">
                        {seed.teams[0]?.score !== undefined ? seed.teams[0].score : 'N/A'}
                    </div>
                </div>
                <hr style={{ margin: '3px', borderTop: 'white !important' }} />
                <div className="row position-relative">
                    <div className="col-9 text-left">
                        {seed.teams[1]?.name || 'No Team'}
                        {seed.winner_slug === seed.teams[1]?.id && (
                            <img
                                src={medalImage}
                                alt="Winner"
                                className="winner-medal"
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    right: '0',
                                    width: '20px',
                                    height: '20px',
                                }}
                            />
                        )}
                    </div>
                    <div className="col-3 text-right">
                        {seed.teams[1]?.score !== undefined ? seed.teams[1].score : 'N/A'}
                    </div>
                </div>
                <div className="hover-dates">
                    <div>Start: {startDate == ''?'Not Set': startDate}</div>
                    <div>Field: {field || 'Not Set'}</div>
                </div>
            </SeedItem>
        </Seed>
    );
};

export default CustomSeed;