import { useSelector } from "react-redux";
import { Axios } from "../Hooks/useAxiosInstance";


export const TeamAPI = () => {

    const { route, user } = useSelector(state => state.default)

    const apis = ({ route, api_token }) => ({
        get: async () => await getRecord(route, api_token),
        getTeams: async (page, limit, filters) => await getRecords(api_token, route, page, limit, filters),
        getTeamById: async (slug) => await getRecordById(slug, route, api_token),
        post: async (data) => await postRecord(data, route, api_token),
        update: async ({ slug, data}) => await updateRecord(slug, data, route, api_token),
        addPlayer: async ({ team_slug = '', player_slug = '' }) => await addPlayerRecord(team_slug, player_slug, api_token),
        removePlayer: async ({ team_slug = '', player_slug = '' }) => await removePlayerRecord(team_slug, player_slug, api_token),
        getTeam: async (slug) => await getTeamRecord(slug, route, api_token),
        getUnregisterTeam: async (tour_slug) => await getUnregisterTeamRecord(tour_slug, route, api_token),
    })

    async function getRecordById(slug, route, api_token) {
        const url = "team/" + slug;
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getRecord(route, api_token) {
        const url = route +"team";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    async function getRecords(api_token, route, page = 1, limit = 10, filters={}) {
        try {
            let url = new URL(`https://api.primetime-tournaments.com/api/team`);
            url.searchParams.append("page", page);
            url.searchParams.append("limit", limit);

            // Add search parameter if it exists
            if (filters.search) {
                const search = filters.search.toLowerCase();
                url.searchParams.append("search", search);
            }
    
            // Add the rest of the filters
            Object.entries(filters).forEach(([key, value]) => {
                if (key !== "search" && value) { // Skip 'search' here, it's already handled
                    url.searchParams.append(key, value);
                }
            });
            const options = {
                headers: {
                    Authorization: api_token,
                    "Content-Type": "application/json"
                }
            };
    
            const response = await Axios.get(url, options);
            return response; // Adjust based on backend response structure
        } catch (error) {
            console.error("Error fetching teams:", error.message);
            throw error;
        }
    }

    async function postRecord(data, route, api_token) {
        const url = route + "team";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'multipart/form-data'
            }
        }
        return await Axios.post(url, data, option);
    }

    async function updateRecord(slug, data, route, api_token) {
        const url = route + "team/update/" + slug;
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'multipart/form-data'
            }
        }
        return await Axios.put(url, data, option);
    }

    async function addPlayerRecord(team_slug = '', player_slug = '', api_token) {
        const url = route + "team/" + team_slug + '/player/' + player_slug;
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.post(url, {}, option);
    }

    async function removePlayerRecord(team_slug = '', player_slug = '', api_token) {
        const url = route + "team/" + team_slug + '/player/' + player_slug;
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.delete(url, option);
    }

    async function getTeamRecord(slug, route, api_token) {
        const url = route + "team/" + slug;
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }


    async function getUnregisterTeamRecord(tour_slug, route, api_token) {
        const url = route + "team/"+ tour_slug +"/unregister";
        const option = {
            headers: {
                "Authorization": api_token,
                'Content-Type': 'application/json'
            }
        }
        return await Axios.get(url, option);
    }

    return apis({ route: route, api_token: user.api_token });

}

