import React from 'react'

import styles from "./styles/RegisteredTeam.module.scss"
import { CardTitle, Description } from '../CustomComponents'
import { FaMapMarkerAlt } from "react-icons/fa";

export default function RegisteredTeam({ classes = "", data = {}, isLive = false }) {
    return (
        <div
            className={`${styles.RegisteredTeam} ${classes}`}
        >
            <div className={styles.teamContainer}>
                <div className={styles.team}>
                    <img
                        src={data.team_image_url}
                        alt={data.team_name}
                        className={styles.teamLogo}
                    />
                    <div className={styles.nameContainer}>
                        <CardTitle
                            text={data.team_name}
                            classes='lc-1'
                        />
                        <Description
                            text={`Age group : ${data.team_age_group}`}
                            classes='fs-9'
                        />
                        <Description
                            text={`${data.member_count} members`}
                            classes='fs-9'
                        />

                    </div>

                </div>
                <div className={styles.tournament}>
                    <img
                        src={data.tournament_image_url}
                        alt='Tournament Logo'
                        className={styles.tournamentLogo}
                    />
                    <div className={styles.nameContainer}>
                        <CardTitle
                            text={data.tournament_name}
                            classes='lc-1'
                        />
                        <div className={styles.location}>
                            <span className={styles.icon}><FaMapMarkerAlt /></span>
                            <Description
                                text={data.tournament_location}
                                classes='fs-9 lc-1'
                            />
                            {
                                isLive ?
                                    <span className={styles.liveBadge}>Live</span>
                                    : ''
                            }
                        </div>

                    </div>

                </div>

            </div>
            <div className={styles.qrContainer}>
                <img
                    src='/images/qr.png'
                    alt='QR Code'
                    className={styles.qrImage}
                />
                <Description
                    text='Team QR'
                    classes='fs-8 text-center mt-2 text-nowrap'
                />

            </div>
        </div>
    )
}