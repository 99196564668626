import React, { useMemo, useState } from 'react'
import { Button, Input, Select} from '../../../Components/CustomComponents'
import { Col, Image, Row } from 'react-bootstrap'
import styles from "../index.module.scss";
import TeamMember from './TeamMember';
import { useMutation, useQueryClient } from 'react-query';
import Upload from "rc-upload"
import _, { set } from 'lodash';
import { Toast } from '../../../Hooks/useToast';
import { FaPlus } from "react-icons/fa6";
import { TeamAPI } from '../../../Api/Team';

const EditTeam = ({ handleClose, team_slug, team_image, team_name, teamSport, teamClass, setTeamSport, setTeamClass }) => {
    const ACCEPT_IMAGE = 'image/png, image/jpeg, image/jpg'

    const teamAPI = TeamAPI();
    const queryClient = useQueryClient();

    const [name, setName] = useState(team_name);
    const [image, setImage] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const sportList = [
        { label: 'Baseball', value: 'Baseball' },
        { label: 'Fast Pitch', value: 'Fast Pitch' },
    ]
    const classList = [
        { label: 'B', value: 'B' },
        { label: 'C', value: 'C' },
        { label: 'REC', value: 'REC' },
        { label: 'Other', value: 'Other' },
    ]

    const handleSportChange = (name, value) => {
        setTeamSport(value)
    }

    const handleClassChange = (name, value) => {
        setTeamClass(value)
    }

    const registerMutation = useMutation(teamAPI.update, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['team'],
                {
                    refetchInactive: true,
                    refetchActive: true,
                })
            handleClose(false)
            setIsSubmitted(false)
        },
        onError: (err) => {
            Toast(err.message, 'error', false)
            setIsSubmitted(false)
        }
    });

    const handleChangeName = (name, value) => {
        setName(value);
    };

    const handleImageChange = (e) => {
        if (!ACCEPT_IMAGE.includes(e.type)) return Toast('Invalid media type', 'error', false)

        setImage(e);
        return false;
    };

    const handleSubmit = () => {
        if (isSubmitted) return;
        if (!name) return Toast("Team name is required", 'error', false);
        if (!teamSport) return Toast("Select sport to create team", 'error', false);
        if (!teamClass) return Toast("Select class to create team", 'error', false);
        // if (_.isEmpty(image)) return Toast("Team image is required", 'error', false);

        setIsSubmitted(true);
        const formData = new FormData();
        formData.append('name', name)
        formData.append('sports_type', teamSport)
        formData.append('class', teamClass)
        formData.append('image_url', image)

        registerMutation.mutate({slug: team_slug, data: formData});
    }

    return (
        <div style={{
            backgroundColor: "#48556F",
            borderRadius: "22px 22px 0px 0px",
            width: "100%",
            height: "100%",
            padding: '20px',
        }}
        >
            <h4 className='text-white' >Edit Your Team</h4>

            <Row>
                <Col md={4} sm={4} xs={4} className='d-flex flex-column align-items-center  '>
                    <Upload
                        beforeUpload={handleImageChange}
                        multiple={false}
                        accept={ACCEPT_IMAGE}
                    >
                        {_.isEmpty(image) ?
                            <Image
                                src={team_image}
                                roundedCircle
                                style={{ width: "80px", height: "80px", borderRadius: "100%" }}
                            /> :
                            <Image
                                src={URL.createObjectURL(image)}
                                roundedCircle
                                style={{ width: "80px", height: "80px", borderRadius: "100%" }}
                            />
                        }
                    </Upload>


                </Col>
                <Col md={8} sm={8} xs={8} className='d-flex align-items-center'>
                        <Row>
                            <Col sm={12}>
                                <label className='text-white'>Team Name</label>
                                <Input classes="mt-2 mb-3" placeholder={name} value={name} onChange={handleChangeName} />
                            </Col>
                            <Col md={6} sm={12}>
                                <label className='text-white'>Team Sport</label>
                                <Select 
                                    options={sportList}
                                    classes="mt-2 mb-3"
                                    placeholder="Select Sport"
                                    value={teamSport}
                                    onChange={handleSportChange}
                                />
                            </Col>
                            <Col md={6} sm={12}>
                                <label className='text-white'>Team class</label>
                                <Select 
                                    options={classList}
                                    classes="mt-2 mb-3"
                                    placeholder="Select Class"
                                    value={teamClass}
                                    onChange={handleClassChange}
                                />
                            </Col>
                        </Row>
                </Col>
            </Row>

            <br></br>
            <Row>
                <Col md={6} sm={12} xs={12} className='d-flex align-items-center'>
                    <Button
                        classes={`${styles.cardBtn} w-100 mt-2`}
                        text="Update"
                        onClick={handleSubmit}
                        isSubmitted={isSubmitted}
                    >
                    </Button>
                </Col>
                <Col md={6} sm={12} xs={12} className='d-flex align-items-center'>
                    <Button
                        classes={`w-100 mt-2`}
                        style={{ background: "#E95B5B", border: "1px solid rgb(230, 149, 149)" }}
                        text="Cancel"
                        onClick={() => handleClose(false)}
                    >
                    </Button>
                </Col>
            </Row>
        </div >
    )
}

export default EditTeam
