import React, { useState, useEffect } from 'react';
import { Bracket } from 'react-brackets';
import { Modal, Button, Form } from 'react-bootstrap';
import CustomSeed from './CustomSeed.js';

const generateWinningRounds = (teams, winningBracket = []) => {
    const rounds = [];
    let numberOfTeams = teams.length;
    let roundNumber = 1;


    while (numberOfTeams > 1) {
        const round = winningBracket[roundNumber]? winningBracket[roundNumber] : [];
        const seeds = [...new Array(Math.ceil(numberOfTeams / 2))].map((_, seedIndex) => {
            // Check if there is a match for this seed
            const match = round[seedIndex+1]? round[seedIndex+1] : null;
            
            return {
                id: seedIndex+1,
                date: new Date().toDateString(),
                teams: match
                    ? [
                        { id: match.teamA.slug, name: match.teamA.Registration_TeamSlug.name, score: match.score_teamA },
                        { id: match.teamB.slug, name: match.teamB.Registration_TeamSlug.name, score: match.score_teamB },
                    ]
                    : [
                        { id: Math.random(), name: 'Select Team 1', score: 0 },
                        { id: Math.random(), name: 'Select Team 2', score: 0 },
                    ],
                round: roundNumber,
                bracket: 'winning',
                winner_slug: match? match.winner_slug : '',
                match_slug: match? match.slug : '',
                startDate: match? match.startDate : '',
                endDate: match? match.endDate : '',
            };
        });

        let title = `Winning Round ${roundNumber}`;
        if (numberOfTeams === 2) {
            title = 'Final Round';
        } else if (numberOfTeams === 4) {
            title = 'Semi Final Round';
        }

        rounds.push({ title, seeds });
        numberOfTeams = Math.ceil(numberOfTeams / 2);
        roundNumber++;
    }

    return rounds;
};

const generateLosingRounds = (teams, losingBracket = []) => {
    const initialTeams = teams.length;
    let rounds = [];
    
    // Calculate number of rounds in the losing bracket
    let c = Math.ceil(initialTeams / 2);
    let numberOfLosingRounds = Math.ceil(Math.log2(c + 1)); // Ensure no more rounds than needed

    let roundIndex = 1;
    let remainingTeams = c; // Initial number of losing teams from the winning bracket
    
    while (remainingTeams > 1) {
        const round = losingBracket[roundIndex]? losingBracket[roundIndex] : [];
        const seeds = [...new Array(Math.ceil(remainingTeams / 2))].map((_, seedIndex) => {
            // Check if there is a match for this losing round seed
            const match = round[seedIndex+1]? round[seedIndex+1] : null;
            
            return {
                id: seedIndex + 1,
                date: new Date().toDateString(),
                teams: match
                    ? [
                        { id: match.teamA.slug, name: match.teamA.Registration_TeamSlug.name, score: match.score_teamA },
                        { id: match.teamB.slug, name: match.teamB.Registration_TeamSlug.name, score: match.score_teamB },
                    ]
                    : [
                        { id: Math.random(), name: 'Select Team 1', score: 0 },
                        { id: Math.random(), name: 'Select Team 2', score: 0 },
                    ],
                round: roundIndex,
                bracket: 'losing',
                match_slug: match? match.slug : '',
                winner_slug: match? match.winner_slug : '',
                startDate: match? match.startDate : '',
                endDate: match? match.endDate : '',
            };
        });

        let title = `Losing Round ${roundIndex}`;
        if (remainingTeams === 2) {
            title = 'Final Round';
        } else if (remainingTeams === 4) {
            title = 'Semi Final Round';
        }

        rounds.push({ title, seeds });
        remainingTeams = Math.ceil(remainingTeams / 2);
        roundIndex++;
    }

    return rounds;
};

const TreeComponent = ({ brackets, teams, ageGroupId }) => {
    const [winning, setWinning] = useState(brackets['winning'] || []);
    const [losing, setLosing] = useState(brackets['losing'] || []);

    useEffect(() => {
        setWinning(brackets['winning'] || []);
        setLosing(brackets['losing'] || []);
    }, [brackets]);

    if (!teams || teams.length === 0) {
        return <div>No teams available to display the bracket.</div>;
    }
    if (Object.keys(winning).length === 0 || Object.keys(losing).length === 0) {
        return <div>No bracket available to display.</div>;
    } 
    const winningRounds = generateWinningRounds(teams, winning);
    const losingRounds = generateLosingRounds(teams, losing);

    return (
        <div style={{ position: 'relative' }}>
            <div className="bracket left-bracket mt-5">
                <Bracket
                    rounds={winningRounds}
                    renderSeedComponent={(props) => (
                        <CustomSeed {...props} />
                    )}
                    roundClassName="bracket-custom"
                    swipeableProps={{
                        enableMouseEvents: true,
                        animateHeight: true,
                        style: {
                          padding: '0 50px 0 0',
                        },
                    }}
                />
            </div>

            <div className="bracket left-bracket mt-5">
                <Bracket
                    rounds={losingRounds}
                    renderSeedComponent={(props) => (
                        <CustomSeed {...props}/>
                    )}
                    roundClassName="bracket-custom"
                    swipeableProps={{
                        enableMouseEvents: true,
                        animateHeight: true,
                        style: {
                            padding: '0 50px 0 0',
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default TreeComponent;
