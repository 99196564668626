import React, { useState } from "react";

import styles from "../index.module.scss"

import { FaCircleXmark } from "react-icons/fa6";
import _ from "lodash";

import { CardDescription } from "../../../Components/CustomComponents";
import { Toast } from "../../../Hooks/useToast";
import GetRegisterForm from "../../Tournaments/Components/GetRegisterForm";
import { PlayerAPI } from "../../../Api/Player";
import { useMutation, useQueryClient } from "react-query";


export default function AddKid({ handleClose }) {
    const QUERY_KEY = 'user/player'
    const playerAPI = PlayerAPI();
    const queryClient = useQueryClient()

    const [isSubmitted, setIsSubmitted] = useState();
    const postMutation = useMutation(playerAPI.post, {
        onSuccess: (data) => {
            const prev_data = queryClient.getQueryData([QUERY_KEY]);
            queryClient.setQueryData([QUERY_KEY], { ...prev_data, data: prev_data?.data ? [...prev_data.data, data.data] : [data.data] });
            handleClose()
        },
        onError: (err) => {
            Toast(err.message, 'error', false)
            setIsSubmitted(false)
        }
    });


    const handleRegistrationForm = (data) => {
        if (isSubmitted) return;
        setIsSubmitted(true);
        postMutation.mutate(data)
    }

    return (
        <div className={`${styles.modalContainer} ${styles.removeWebKit}`} >

            <div className='d-flex justify-content-between w-100 mb-4'>
                <div className='d-flex flex-column'>
                    <p className='fs-4 fw-medium m-0'>
                        Add Kid
                    </p>
                </div>

                <FaCircleXmark onClick={handleClose} style={{ cursor: 'pointer', fontSize: "28px", }} />
            </div>


            <div className=' w-100'>
                <GetRegisterForm handleRegistration={handleRegistrationForm} isSubmitted={isSubmitted} />
            </div>

        </div>
    )
}