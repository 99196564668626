import React, { useEffect, useState } from 'react';
import cssStyles from '../index.module.css';
import { Image, } from 'react-bootstrap';
import { IoIosArrowDown } from 'react-icons/io';

const TeamDropDownMenu = ({ data = [], selectedTeam = 0, setSelectedItem = () => { } }) => {
  const [dropToggle, setDropToggle] = useState(true);
  const team = data[selectedTeam]

  const handleSelection = () => {
    setDropToggle(!dropToggle);
  }

  useEffect(() => {
    handleSelection();
  }, [])


  return (
    <div className={cssStyles.dropdown} style={{ border: 'none', marginBottom: '10px' }}>
      <div className={cssStyles.dropBtn} onClick={handleSelection} style={{ backgroundColor: '#0D0D1C ' }}>

        <div className='d-flex flex-row'>

          <div xs={2} className=' d-flex align-items-center '>
            <Image
              style={{ width: '50px' }}
              src={team.image_url}
            />
          </div>

          <div xs={8} className='mx-4'>
            <span className='fs-5'>{team.name}</span>
            <p className='fs-7 m-0'>{`Age group: ${team.group_type}`}</p>
            <p className='fs-7 m-0'>{`${team.member_count} members`}</p>
          </div>

          <div xs={8} className='ms-auto align-items-center d-flex '>
            <span className='fs-5'>
              <IoIosArrowDown />
            </span>
          </div>

        </div>

      </div>
      {
        dropToggle ?
          <div className={cssStyles.dropdownContent} style={{ backgroundColor: '#0D0D1C', zIndex: 5, bottom: 'auto' }}>
            {
              data.map((item, index) => (
                <div key={index} onClick={() => { setSelectedItem(index) }}>

                  <div className='d-flex flex-row'>
                    <div xs={2} className=' d-flex align-items-center '>
                      <Image
                        style={{ width: '50px' }}
                        src={item.image_url}
                      />
                    </div>
                    <div xs={8} className='mx-4 my-2 text-white'>
                      <span className='fs-6'> {item.name}</span>
                      <br></br>
                      <span className='fs-8 m-0'> Age group:{item.group_type}</span>
                      <br></br>
                      <p className='fs-8 m-0'>{item.member_count} members</p>
                    </div>

                  </div>
                  <hr className='m-0' style={{ borderTopColor: "#f1f1f1" }}></hr>
                </div>
              ))}
          </div>
          : ''
      }
    </div>


  )
}

export default TeamDropDownMenu