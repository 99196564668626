import React from "react";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { Button, Description, Input, Title, UnorderedList } from "../../Components/CustomComponents";
import { ACCOUNT_ENUM } from "./Route";


export default function SetPassword({ setPage }) {

    const handleBackButtonClick = () => {
        setPage(ACCOUNT_ENUM.OTP)
    }

    const handleSubmit = () => {
        setPage(ACCOUNT_ENUM.LOGIN)
    }

    return (
        <AccountLayout
            hasBackButton={true}
            handleBackButton={handleBackButtonClick}
        >
            <Title text="Set New Password" classes="mb-2" />
            <Description
                text="Please enter your new password"
                classes="text-center fs-7 mb-4"
            />
            <Input
                placeholder="New Password"
                classes="mt-2 mb-1"
            />
            <Input
                placeholder="Confirm Password"
                classes="mt-1 mb-2"
            />
            <UnorderedList
                lists={[
                    'At least 12 characters long but 14 or more is better.',
                    'A combination of uppercase letters, lowercase letters, numbers, and symbols.'
                ]}
                classes="mt-2 mb-2"
            />
            <Button
                text='Continue'
                classes="mt-4 mb-2"
                onClick={handleSubmit}
            />

        </AccountLayout>
    )
}