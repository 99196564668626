import React, { useEffect, useState, useCallback } from "react";
import { TournamentAPI } from "../../Api/Tournament";
import { useQuery } from "react-query";
import { Toast } from "../../Hooks/useToast.js";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { WEB_ROUTES } from "../../constants";
import { baseRoute } from "../../Helper";
import styles from "../../Components/Layouts/styles/PrimeTimeLayout.module.scss";
import debounce from "lodash.debounce";
import Default from '../../assets/images/default.jpg';



export default function TournamentSearch() {
    const tournamentAPI = TournamentAPI();
    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState({});
    const [page, setPage] = useState(1);
    const [paginationDetail, setPaginationDetail] = useState([]);
    const [debouncedFilters, setDebouncedFilters] = useState({});
    const [tournaments, setTournaments] = useState([]);

    const updateFilters = useCallback(
        debounce((newFilters) => {
          setDebouncedFilters(newFilters);
          setPage(1); // Reset to the first page on filter change
        }, 300),
        []
    );

    useEffect(() => {
        updateFilters({ search, ...filters });
    }, [search, filters, updateFilters]);

    //get the tournaments
    const { data, isError, isLoading } = useQuery(
        ['tournaments', page, debouncedFilters],
        {
        queryFn: async () => await tournamentAPI.getTournaments(page, 8, debouncedFilters),
        onSuccess: (data) => {
            setTournaments(data.data);
            setPaginationDetail(data.links);
            setPage(data.links.current);
        },
        onError: (err) => Toast(err.message, 'error', false),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
    })

    const handleFilterChange = (key, value) => {
        setFilters((prev) => ({ ...prev, [key]: value }));
    };

    const handleResetFilters = () => {
        setSearch("");
        setFilters({});

    };

    const handlePreviousPage = () => {
        if (paginationDetail?.current > 1) {
            setPage((prev) => prev - 1);
        }
    };
      
    const handleNextPage = () => {
        if (paginationDetail?.current < paginationDetail?.total) {
            setPage((prev) => prev + 1);
        }
    };

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
      };

    return (
        <div className={styles.sectionContainer}>
            <div className="row d-flex justify-content-center">
                <div className="col-md-6 col-sm-12 mb-2">
                    <input
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search for Tournaments..."
                        className="form-control"
                        style={{
                            borderRadius: '25px',
                            border: '1px solid #ccc',
                            fontSize: '16px',
                            outline: 'none',
                            background:"#edeff3",
                        }}
                    />
                </div>
                <div className="col-md-2 col-sm-6 d-flex justify-content-evenly">
                    <button
                        className={`btn ${styles.gdBtn}`}
                        onClick={handleResetFilters}
                    >
                    Reset
                    </button>
                </div>               
            </div>

            <div className="row mb-4 d-flex justify-content-center">
                {/* Sport Dropdown */}
                <div className="col-md-2 col-sm-12">
                    <label style={{ fontSize: '16px', fontWeight: 'bold', color: 'black'}}>Sport</label>
                    <select
                        value={filters.sports_type|| ""}
                        onChange={(e) => handleFilterChange("sports_type", e.target.value)}
                        style={{
                        width: '100%',
                        padding: '10px',
                        marginTop: '5px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        fontSize: '16px',
                        background:"#edeff3",
                        }}
                    >
                        <option value="">Select Sport</option>
                        <option value="Fast Pitch">Fast Pitch</option>
                        <option value="Baseball">Baseball</option>
                    </select>
                </div>

                {/* Season Dropdown */}
                <div className="col-md-2 col-sm-12">
                    <label style={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}>Season</label>
                    <select
                        value={filters.season|| ""}
                        onChange={(e) => handleFilterChange("season", e.target.value)}
                        style={{
                        width: '100%',
                        padding: '10px',
                        marginTop: '5px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        fontSize: '16px',
                        background:"#edeff3",
                        }}
                    >
                        <option value="">Select Season</option>
                        <option value="2025">2025 Season</option>
                        <option value="2024">2024 Season</option>
                        <option value="2023">2023 Season</option>
                    </select>
                </div>

                {/* Age Input */}
                <div className="col-md-2 col-sm-12">
                    <label style={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}>Age</label>
                    <select
                        value={filters.age_group|| ""}
                        onChange={(e) => handleFilterChange("age_group", e.target.value)}
                        style={{
                        width: '100%',
                        padding: '10px',
                        marginTop: '5px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        fontSize: '16px',
                        background:"#edeff3",
                        }}
                    >
                        <option value="">Select Age Group</option>
                        <option value="U_6">6U</option>
                        <option value="U_8">8U</option>
                        <option value="U_10">10U</option>
                        <option value="U_12">12U</option>
                        <option value="U_14">14U</option>
                        <option value="U_16">16U</option>
                        <option value="U_18">18U</option>
                    </select>
                </div>

                {/* Start Date Input */}
                <div className="col-md-2 col-sm-12">
                    <label style={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}>Start Date</label>
                    <input
                        value={filters.start|| ""}
                        onChange={(e) => handleFilterChange("start", e.target.value)}
                        type="date"
                        style={{
                        width: '100%',
                        padding: '10px',
                        marginTop: '5px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        fontSize: '16px',
                        background:"#edeff3",
                        }}
                    />
                </div>

                <div className="col-md-2 col-sm-12">
                    <label style={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}>Class</label>
                    <select
                        value={filters.class|| ""}
                        onChange={(e) => handleFilterChange("class", e.target.value)}
                        style={{
                        width: '100%',
                        padding: '10px',
                        marginTop: '5px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        fontSize: '16px',
                        background:"#edeff3",
                        }}  
                    >
                        <option value="">Select Class</option>
                        <option value="A">Class A</option>
                        <option value="B">Class B</option>
                        <option value="C">Class C</option>
                        <option value="REC">Rec/All-Star League</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
            </div>

            {/* Tournament List */}
            <div className="container">
                        {isLoading ? (
                <p>Loading...</p>
            ) : (
                <div className="row">
                    {tournaments.length > 0 ? (
                        <>
                            {tournaments.map((tournament) => (
                                <div key={tournament.id} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                                    <div className="card h-100" style={{ borderRadius: '10px', background: '#848a93' }}>
                                        <img 
                                            className="card-img-top" 
                                            src={tournament.media_url || Default} 
                                            style={{ width: '100%', height: '250px', objectFit: 'contain', borderRadius: '4px', background: '#fff' }} 
                                            alt="Tournament image"
                                        />
                                        <div className="card-body">
                                            <h5 className="text-light mb-2">{tournament.name}</h5>
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="text-light">{tournament.sports_type}</div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="text-light">{tournament.class}</div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="text-light">{formatDateTime(tournament.start_date)}</div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="text-light">{tournament.season}</div>
                                                </div>
                                            </div>
                                            <Link to={`${WEB_ROUTES.TOURNAMENT}/${tournament.slug}`}>
                                                <button className={`btn mt-3 ${styles.gdBtn}`}>
                                                    View
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-2 col-sm-6 d-flex justify-content-evenly">
                                    <button
                                        onClick={handlePreviousPage}
                                        className={`btn ${styles.gdBtn}`}
                                        disabled={page === 1}
                                    >
                                        Previous
                                    </button>
                                    <span className="text-dark">{page}/{paginationDetail.total}</span>
                                    <button
                                        onClick={handleNextPage}
                                        className={`btn ${styles.gdBtn}`}
                                        disabled={page === paginationDetail.total}
                                    >
                                        Next
                                    </button>
                                </div> 
                            </div>
                        </>
                    ) : (
                        <p>No tournaments</p>
                    )}
                </div>
            )}
            </div> 
        </div>

    );
}
