import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
// import TeamBanner from "../../Components/Cards/TeamBanner";
import cssStyles from "./index.module.css";
import Tabs from "../../Components/Tabs/Tabs";
import _ from "lodash";
import { Button, Loader } from "../../Components/CustomComponents";
import DetailsContent from "./DetailsContent";
import TeamTournaments from "./TeamTournaments.js";
import { ROUTES } from "./Route";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { Toast } from "../../Hooks/useToast";
import { useMutation, useQueryClient } from "react-query";
import { TeamAPI } from "../../Api/Team.js";
import TeamRecords from "./TeamRecords.js";

const ViewTeamScreen = ({ setPage, teamId = "", details = {} }) => {
  const teamAPI = TeamAPI();
  const {
    isViewer: IS_VIEWER,
    isCoach: IS_COACH,
    route: base_route,
    user
  } = useSelector((state) => state.default);
  const queryClient = useQueryClient();
  const QUERY_KEY = base_route + "/team/" + teamId;

  const [key, setKey] = useState("details");

  const array = useMemo(
    () => [
      {
        value: "details",
        label: "Details",
      },
      {
        value: "records",
        label: "Records",
      },
      {
        value: "tournaments",
        label: "Tournaments",
      },
    ],
    []
  );

  const { data, isError, isFetchedAfterMount } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => await teamAPI.getTeam(teamId),
    select: (data) => data.data,
    onError: (err) => Toast(err.message, "error", false),
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    initialData: details,
  });

  const handleBackButtonClck = () => {
    setPage(ROUTES.TEAM);
  };

  const tabContent = () => {
    switch (key) {
      case "details":
        return <DetailsContent data={data} />;
      case "records":
        return <TeamRecords team_slug={data.slug}/>;
      case "tournaments":
        return <TeamTournaments team_slug={data.slug}/>;
      default:
        return <DetailsContent />;
    }
  };

  return (
    <div className={cssStyles.ViewTeam}>
      {!isFetchedAfterMount ? (
        <Loader />
      ) : isError || _.isEmpty(data) ? (
        ""
      ) : (
        <div className="mt-2">
          <Row className="d-flex justify-content-between">
            <Col xs={12} md={1} className="mb-2">
              <Button
                text="Back"
                onClick={handleBackButtonClck}
                classes="btn btn-primary"
              />
            </Col>
            <Col xs={12} md={4}>
              <div className={cssStyles.beforeTabs}>
                <Tabs tabs={array} current={key} onChange={setKey} />
              </div>
            </Col>
          </Row>
          <Row >
            <Col sm={12}>
              {tabContent()}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ViewTeamScreen;
