import { useStripe, useElements, Elements } from "@stripe/react-stripe-js";
import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Image, Table } from "react-bootstrap";
import cssStyles from "../index.module.css";
import {
  Button,
  CardTitle,
  Description,
  Loader,
} from "../../../Components/CustomComponents";
import CardDropDownMenu from "./CardDropDownMenu";
import _ from "lodash";
import PaymentFormModal from "./PaymentPopUp";
import PaymentProcess from "./PaymentPopUp";
import { BASE_URL } from "../../../constants";

const CheckOutSummary = ({
  key,
  teamData,
  data,
  isSubmitted = false,
  isDisabled = false,
  setIsSubmitted = () => { },
  handleCheckOutSubmit = () => { },
  handleCheckOutSubmit2 = () => { },
}) => {

  const [paymentForm, setPaymentForm] = useState(false);

  const handleSubmit = useCallback(() => {
    setPaymentForm(true);
  }, []);

  const handlePayLater = useCallback(() => {
    setIsSubmitted(true);
    handleCheckOutSubmit2();
  }, []);

  const handleCheckOut = (card_token) => {
    if (isSubmitted || isDisabled) return;
    setIsSubmitted(true);
    handleCheckOutSubmit(card_token);
  }

  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderRadius: "22px 22px 0px 0px",
        width: "100%",
        padding: "16px",
      }}
    >
      <h4 className="text-dark">Checkout Summary</h4>

      <p className="text-dark fs-7">
        Please check all the details and select payment method
      </p>

      <div className="flex-row d-flex align-items-center text-dark">
        <div className="d-flex ">
          <Image
            src={BASE_URL + data?.thumbnail_url}
            roundedCircle
            className={cssStyles.avatarImage}
          />
        </div>
        <div className="ms-3">
          <CardTitle text={data?.name} classes="mt-1 fs-5 lc-1 text-dark" />

          <div className="d-flex">
            <Description
              classes="mb-2 lc-1 fs-9 text-dark"
              text={data?.location}
            />
          </div>
        </div>
      </div>

      <Table className="text-dark fw-medium mt-3">
        <tr>
          <td>Entry Fee</td>
          <td
            className="align-right"
            style={{ textAlign: "right" }}
          >{`$${data?.registration_fee}`}</td>
        </tr>
        <tr>
          <td>Sub Total</td>
          <td
            className="align-right"
            style={{ textAlign: "right" }}
          >{`$${data?.registration_fee}`}</td>
        </tr>
        {/* <tr>
          <td>Platform Fee</td>
          <td
            className="align-right"
            style={{ textAlign: "right" }}
          >{`$${data?.platform_fee}`}</td>
        </tr> */}
        <tr className="fs-5">
          <td>Total</td>
          <td className="align-right" style={{ textAlign: "right" }}>{`$${data?.registration_fee + 2.00
            }`}</td>
        </tr>
      </Table>
      <br/>
      <br/>
      <br/>
      <Button
        classes={`${cssStyles.cardBtn} w-100 mt-2`}
        text="Continue to Pay"
        onClick={handleSubmit}
        disabled={isDisabled}
        isSubmitted={isSubmitted}
      />
      <Button
        classes={`${cssStyles.cardBtn} w-100 mt-2`}
        text="Pay Later"
        onClick={handlePayLater}
        disabled={isDisabled}
        isSubmitted={isSubmitted}
      />
      <br></br>
      <PaymentProcess
        data={data}
        teamData={teamData}
        paymentPopup={paymentForm}
        setPaymentProcess={setPaymentForm}
        handleCheckout={handleCheckOut}
      />
    </div>
  );
};

export default CheckOutSummary;
