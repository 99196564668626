import _ from "lodash"
import moment from "moment"
import { CONTAINER_KEYS } from "../constants"

const baseRoute = (name = '') => {
    return `${process.env.REACT_APP_DOMAIN_DIR}/${name}`
}

const apiBaseUrl = () => {
    return process.env.REACT_APP_API_DOMAIN + 'api/'
}

const localStorageToken = () => {
    return process.env.REACT_APP_WEB_STORAGE_TOKEN;
}

const transformData = (data => _.isEmpty(data?.data) ? data : data?.data)


const getAgeFromDateOfBirth = (date) => {
    return `${(Math.floor(moment().diff(date, 'years')) || 0)}`
}


const removeSplashScreen = () => {
    document.getElementById(CONTAINER_KEYS.SPLASH_SCREEN_IMAGE).classList.add('animate-splash-image')
    document.getElementById(CONTAINER_KEYS.SPLASH_SCREEN).classList.add('animate-splash')
    setTimeout(() => {
        document.getElementById(CONTAINER_KEYS.SPLASH_SCREEN).classList.add('d-none')
    }, 2700)
}

export { baseRoute, apiBaseUrl, localStorageToken, transformData, getAgeFromDateOfBirth, removeSplashScreen } 